import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import HTTPServices from "../../Utils/HTTPServices";
import Select from "react-select";
import moment from "moment";

const SkinFoldExam = ({players}) => {
    const [startDate, setStartDate] = useState(new Date());
    const [datum, setDatum] = useState({});
    const [player, setPlayer] = useState(null);
    const [playerChecks, setPlayerChecks] = useState([]);

    useEffect(() => {
        if (players.length > 0)
            setPlayer(players[0])
    }, [players]);

    useEffect(() => {
        if (player) {
            let client = new HTTPServices()
            client.getSkinfold(player.id)
                .then((response) => {
                    let datum = response.data.exams.map((item) => {
                        let tmp = item.results.map((result) => {
                            let key = result.name
                            let value = result.values[0].value
                            return {[key]: value}
                        })
                        let result = {date: item.date}
                        tmp.forEach((item) => {
                            result = {...result, ...item}
                        })
                        return result
                    })
                    setPlayerChecks(datum)
                }).catch((error) => {
                    console.log(error)
                })
        }
    }, [player]);



    const handleChange = (event) => {
        setDatum({...datum, [event.target.name]: parseFloat(event.target.value)});
    }

    const calculateS6P = (datum) => {
        let keys = ['triceps', 'subescapular', 'cresta_iliaca', 'supraespinal', 'abdominal', 'pantorrilla'];
        let sum = 0;
        for (let key of keys) {
            sum += datum[key] || 0;
        }
        return sum;
    }

    const calculateS8P = (datum) => {
        let keys = ['triceps', 'subescapular', 'cresta_iliaca', 'supraespinal', 'abdominal', 'pantorrilla', 'biceps', 'pierna'];
        let sum = 0;
        for (let key of keys) {
            sum += datum[key] || 0;
        }
        return sum;
    }

    const S6PColor = (datum) => {
        let value = calculateS6P(datum);
        if (value < 25) {
            return "aqua";
        } else if (value < 35) {
            return "green";
        } else if (value < 40) {
            return "yellow";
        } else if (value < 50) {
            return "orange";
        } else {
            return "red";
        }
    }

    const sendPlayersData = () => {
        let client = new HTTPServices()

        client.uploadSkinfold(datum, player.id, moment(startDate).format('X'))
            .then((data) => {
                setPlayerChecks([...playerChecks, {...datum, date: startDate}])
                setStartDate(new Date())
                setDatum({})
            }).catch((error) => {
                console.log(error)
            })
    }


    return <div className="row justify-content-end">
        <div className="col-4">
            <Select
                placeholder="Selecciona un jugador"
                value={player}
                onChange={(e) => {
                    setPlayer(e);
                }}
                options={players}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
            />
        </div>
        <div className="row mt-3">
            <div className="col-12">
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th scope="col" className="col-2">Fecha</th>
                        <th scope="col" className="col-1">Peso (KG.)</th>
                        <th scope="col" className="col-1">Triceps</th>
                        <th scope="col" className="col-1">Subescapular</th>
                        <th scope="col" className="col-1">Cresta Iliaca</th>
                        <th scope="col" className="col-1">Supraespinal</th>
                        <th scope="col" className="col-1">Abdominal</th>
                        <th scope="col" className="col-1">Pantorrilla</th>
                        <th scope="col" className="col-1">Biceps</th>
                        <th scope="col" className="col-1">Pierna</th>
                        <th scope="col" className="col-1">S6P</th>
                        <th scope="col" className="col-1">S8P</th>
                    </tr>
                    </thead>
                    <tbody>
                    {playerChecks && playerChecks.map((item, index) => {
                        return <tr key={index}>
                            <td>{moment(item.date).format('DD/MM/YYYY')}</td>
                            <td>{item.weight}</td>
                            <td>{item.triceps}</td>
                            <td>{item.subescapular}</td>
                            <td>{item.cresta_iliaca}</td>
                            <td>{item.supraespinal}</td>
                            <td>{item.abdominal}</td>
                            <td>{item.pantorrilla}</td>
                            <td>{item.biceps}</td>
                            <td>{item.pierna}</td>
                            <td className={`${S6PColor(item)}`}>
                                <span className="w-100 text-center">{calculateS6P(item).toFixed(1)}</span>
                            </td>
                            <td>
                                <span>{calculateS8P(item).toFixed(1)}</span>
                            </td>
                        </tr>
                    })}
                    <tr>
                        <td>
                            <DatePicker
                                className="fit-input"
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                dateFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                            />
                        </td>
                        <td>
                            <input min={0} step={0.1} className="fit-input" type="number" name="weight" value={datum.weight || 0}
                                   onChange={handleChange}/>
                        </td>
                        <td>
                            <input min={0} step={0.1} className="fit-input" type="number" name="triceps" value={datum.triceps || 0}
                                   onChange={handleChange}/>
                        </td>
                        <td>
                            <input min={0} step={0.1} className="fit-input" type="number" name="subescapular" value={datum.subescapular || 0}
                                   onChange={handleChange}/>
                        </td>
                        <td>
                            <input min={0} step={0.1} className="fit-input" type="number" name="cresta_iliaca" value={datum.cresta_iliaca || 0}
                                   onChange={handleChange}/>
                        </td>
                        <td>
                            <input min={0} step={0.1} className="fit-input" type="number" name="supraespinal" value={datum.supraespinal || 0}
                                   onChange={handleChange}/>
                        </td>
                        <td>
                            <input min={0} step={0.1} className="fit-input" type="number" name="abdominal" value={datum.abdominal || 0}
                                   onChange={handleChange}/>
                        </td>
                        <td>
                            <input min={0} step={0.1} className="fit-input" type="number" name="pantorrilla" value={datum.pantorrilla || 0}
                                   onChange={handleChange}/>
                        </td>
                        <td>
                            <input min={0} step={0.1} className="fit-input" type="number" name="biceps" value={datum.biceps || 0}
                                   onChange={handleChange}/>
                        </td>
                        <td>
                            <input min={0} step={0.1} className="fit-input" type="number" name="pierna" value={datum.pierna || 0}
                                   onChange={handleChange}/>
                        </td>
                        <td className={`${S6PColor(datum)}`}>
                            <span className="w-100 text-center">{calculateS6P(datum).toFixed(1)}</span>
                        </td>
                        <td>
                            <span>{calculateS8P(datum).toFixed(1)}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className="row justify-content-end">
                    <button className="btn btn-primary"
                            onClick={sendPlayersData}>Agregar
                    </button>
                </div>
            </div>
        </div>
    </div>

}

export default SkinFoldExam;