import Swal from 'sweetalert2'
import React, {useEffect, useState} from "react";
import axios from "axios";
import moment from "moment/moment";
import SLABTemplate from "../../Template/SLABTemplate.component";
import {BACKEND_URL} from "../../constants";
import {BallTriangle} from "react-loader-spinner";
import {useNavigate} from "react-router-dom";
import Tabs from "../../Components/Tabs/Tabs";
import LoadExamComponent from "../../Components/LoadExam/LoadExam.component";
import Select from "react-select";
import HTTPServices from "../../Utils/HTTPServices";
import './NutritionPage.styles.css';
import DatePicker from "react-datepicker";
import SkinFoldExamComponent from "../../Components/SkinFoldResult/SkinFoldExam.component";
import SkinFoldExam from "../../Components/SkinFoldResult/SkinFoldExam.component";

const NutritionPage = () => {

    let user = JSON.parse(localStorage.getItem("user"));

    const [teams, setTeams] = useState(null);
    const [team, setTeam] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [players, setPlayers] = useState([]);


    const [playerChecks, setPlayerChecks] = useState([]);
    const [player, setPlayer] = useState(null);

    useEffect(() => {
        document.title = 'SLAB - Nutrición';
        const getTeams = async () => {
            let client = new HTTPServices();
            client.getTeams()
                .then((response) => {
                   setTeams(response.data.teams);
                    setLoading(false)
                }).catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        localStorage.clear();
                        navigate('/login');
                    }
                })
        }
        getTeams();
    }, []);

    useEffect(() => {
        const getTeam = async (teamId) => {
            setLoading(true)
            let client = new HTTPServices();
            client.getTeam(teamId)
                .then((response) => {
                    setLoading(false)
                    let team = response.data;
                    team.players = team.players.sort((a, b) => a.name.localeCompare(b.name));
                    let players = team.players
                        .filter((player) => player.role.name === "player")
                        .map((player) => {
                            return {
                                id: player.id,
                                name: player.name,
                                age: moment().diff(moment(player.birthdate), 'years'),
                                height: player.currentData.height,
                                weight: player.currentData.weight,
                            }
                        })
                        .sort((a, b) => a.name.localeCompare(b.name));
                    setPlayers(players);
                    setTeam(team);
                }).catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        navigate('/login');
                    }
                    console.log(error);
                })
        }

        if (teams !== null && teams.length) {
            let team = teams[0];
            document.title = 'SLAB - Nutrición - ' + team.name;
            getTeam(team.id);
        }
    }, [teams]);

    const sendPlayersData = async () => {
        setLoading(true)
        let config = {
            method: 'put',
            url: `${BACKEND_URL}/teams/${team.team.id}/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            data: {
                players: players
            }
        };

        axios.request(config).then((response) => {
            setLoading(false)
            Swal.fire("Datos actualizados", "Los datos de los jugadores han sido actualizados", "success");
            // console.log(response);
        }).catch((error) => {
            console.log(error);
            if (error.response.status === 401) {
                localStorage.clear();
                navigate('/login');
            }

        })
    }

    return (
        <SLABTemplate title="Nutrición">
            <div className="row justify-content-center">
                <span className="font-weight-bold h1">Estado nutricional Plantel</span>
                <p className="text-center">Aquí podras obtener distintas herramientas que te ayudaran a planificar tu
                    trabajo con el plantel, con información de los jugadores, resumen de
                    los analisis generados y el estado actual del jugador. </p>
                <hr/>
            </div>
            <div className="row">
                <div className="col-2">
                    <img className="w-100" src={user.company.photo} alt="team-logo"/>
                </div>
                <div className="col-10">
                    <span className="h2 color-main">{user.company.name}</span>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col">
                    <span className="h2 color-main ">Jugadores</span>
                    {
                        loading ?
                            <div className="row justify-content-center mt-5">
                                <BallTriangle
                                    height={100}
                                    width={100}
                                    radius={5}
                                    color="#011634"
                                    ariaLabel="ball-triangle-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div> :
                            <div>
                                <Tabs
                                    pages={[
                                        {
                                            id: "home",
                                            name: "Plantel",
                                            component: <>
                                                <div className="row mt-5">
                                                    <div className="col">
                                                        <span className="h2 color-main ">Jugadores</span>
                                                        <table className="table table-hover">
                                                            <thead>
                                                            <tr>
                                                                <th scope="col">Nombre</th>
                                                                <th scope="col">Edad</th>
                                                                <th scope="col">Estatura (cm)</th>
                                                                <th scope="col">Peso (Kg.)</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {players && players.map((player, index) =>
                                                                <tr key={`row-${index}`}>
                                                                    <td key={`name-${index}`}>
                                                                        <span>{player.name}</span>
                                                                    </td>
                                                                    <td key={`birth-${index}`}>
                                                                        {player.age} años
                                                                    </td>
                                                                    <td key={`height-${index}`}>
                                                                        <input
                                                                            key={`input1-${index}`}
                                                                            type="number"
                                                                            value={player.height}
                                                                            onChange={(e) => {
                                                                                let newPlayers = players.map((p) => {
                                                                                    if (p.id === player.id) {
                                                                                        p.height = e.target.value;
                                                                                    }
                                                                                    return p;
                                                                                });
                                                                                setPlayers(newPlayers);
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td key={`weight-${index}`}>
                                                                        <input
                                                                            key={`input2-${index}`}
                                                                            type="number"
                                                                            value={player.weight}
                                                                            onChange={(e) => {
                                                                                let newPlayers = players.map((p) => {
                                                                                    if (p.id === player.id) {
                                                                                        p.weight = e.target.value;
                                                                                    }
                                                                                    return p;
                                                                                });
                                                                                setPlayers(newPlayers);
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-end">
                                                    <button className="btn btn-primary"
                                                            onClick={sendPlayersData}>Actualizar
                                                    </button>
                                                </div>
                                            </>
                                        },
                                        {
                                            id: "eating-plan",
                                            name: "Calculo de ingesta pre-competicion",
                                            component: <div className="row mt-5">
                                                <div className="col">
                                                    <span className="h2 color-main ">Ingesta pre-competición</span>
                                                    <table className="table table-hover">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">Nombre</th>
                                                            <th scope="col">Estatura (cm)</th>
                                                            <th scope="col">Peso (Kg.)</th>
                                                            <th scope="col">Proteinas</th>
                                                            <th scope="col">H. de C.</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {players && players.map((player, index) =>
                                                            <tr key={`2-row-${index}`}>
                                                                <td>
                                                                    <span>{player.name}</span>
                                                                </td>
                                                                <td>
                                                                    {player.height} cm.
                                                                </td>
                                                                <td>
                                                                    {player.weight} Kg.
                                                                </td>
                                                                <td>
                                                                    {(player.weight * 2.2).toFixed(1)} gr.
                                                                </td>
                                                                <td>
                                                                    {player.weight * 6} gr.
                                                                    - {player.weight * 8} gr.
                                                                </td>
                                                            </tr>
                                                        )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        },
                                        {
                                            id: "skinfold",
                                            name: "Sumatoria de pliegues",
                                            component:
                                                <>
                                                    <div className="row">
                                                        <h1>Sumatoria de pliegues</h1>
                                                    </div>
                                                    <div>
                                                        <SkinFoldExam players={players}/>
                                                    </div>
                                                </>
                                    }
                                        ]}
                                />
                            </div>
                    }
                </div>
            </div>


        </SLABTemplate>
    )
}

export default NutritionPage;