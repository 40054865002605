
const IsokineticCalculator = (exam) => {
    let torqueResult = exam.results.find((result) => result.name.includes("Torque"));

    let timeResult = exam.results.find((result) => result.name.includes("Tiempo"));

    let QDer = torqueResult.values.find((value) => value.name.includes("Quadricep Der")).value;
    let QIzq = torqueResult.values.find((value) => value.name.includes("Quadricep Izq")).value;
    let IDer = torqueResult.values.find((value) => value.name.includes("Isquio Der")).value;
    let IIzq = torqueResult.values.find((value) => value.name.includes("Isquio Izq")).value;

    let QDerTime = timeResult.values.find((value) => value.name.includes("Quadricep Der")).value;
    let QIzqTime = timeResult.values.find((value) => value.name.includes("Quadricep Izq")).value;
    let IDerTime = timeResult.values.find((value) => value.name.includes("Isquio Der")).value;
    let IIzqTime = timeResult.values.find((value) => value.name.includes("Isquio Izq")).value;

    let RelDer =  IDer / QDer;
    let RelIzq =  IIzq / QIzq;

    let QuadRatio =  100 * ((Math.max(QDer, QIzq) / Math.min(QDer, QIzq)) - 1);
    let IzqRatio =  100 * ((Math.max(IDer, IIzq) / Math.min(IDer, IIzq)) - 1);

    return {
        QDer,
        QIzq,
        IDer,
        IIzq,
        RelDer,
        RelIzq,
        QuadRatio,
        IzqRatio,
    }
}

export default IsokineticCalculator;
