import React from "react";
import {components} from "react-select";

export const round = (value, precision) => {
    let multiplier = Math.pow(10, precision || 0);
    if (Number.isFinite(value))
        return Math.round(value * multiplier) / multiplier;
    else
        return 0;
}

export const mean = (values) => {
    return values.length ? values.reduce((a, b) => a + b) / values.length : 0;
}

export const std = (values) => {
    const n = values.length;
    const m = mean(values);
    return Math.sqrt(values.map(x => Math.pow(x - m, 2)).reduce((a, b) => a + b) / n);
}

export const mu = (values) => {
    return mean(values);
}

export const median = (values) => {
    const sorted = values.slice().sort((a, b) => a - b);
    const n = sorted.length;
    return n % 2 === 0 ? (sorted[n / 2 - 1] + sorted[n / 2]) / 2 : sorted[Math.floor(n / 2)];
}

export const max = (values) => {
    return values.length ? Math.max(...values) : 0;
}

export const min = (values) => {
    return values.length ? Math.min(...values) : 0;
}

export const thousandsSeparator = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const getTspanGroups = (value, maxLineLength = 10, maxLines = 3) => {
    const words = value.split(' ')

    // type linesAcc = {
    //     lines: string[],
    //     currLine: string
    // }

    //reduces the words into lines of maxLineLength
    const assembleLines = words.reduce( (acc, word) => {
        //if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
        if ( (word + acc.currLine).length > maxLineLength && acc.currLine !== '') {
            return {
                lines: acc.lines.concat([acc.currLine]),
                currLine: word
            }
        }
        //otherwise add the word to the current line
        return {
            ...acc,
            currLine: acc.currLine + ' ' + word
        }

    }, {lines: [], currLine: ''})

    //add the ending state of current line (the last line) to lines
    const allLines = assembleLines.lines.concat([assembleLines.currLine])

    //for now, only take first 2 lines due to tick spacing and possible overflow
    const lines = allLines.slice(0, maxLines)
    let children = []
    let dy = 20

    lines.forEach( (lineText, i) => {
        children.push(
            <tspan x={0} dy={dy} key={i}>
                {
                    // if on the second line, and that line's length is within 3 of the max length, add ellipsis
                    (1 === i && allLines.length > 2) ? lineText.slice(0, maxLineLength - 3) + '...' : lineText
                }
            </tspan>
        )
        //increment dy to render next line text below
        dy = 15
    });

    return children
}

export const wellnessIsValid = (value) => {
    // numbers from 0 to 10, L, RTP, P, E, A
    let regex = /^(10|[1-9]|L|R|P|E|A)$/;
    return regex.test(value);
}

export const loadPerseptionColor = (value) => {
    switch (value) {
        case '10':
            return '#be0000';
        case '9':
            return '#fd0000';
        case '8':
            return '#fdbe00';
        case '7':
            return '#fdbe00';
        case '6':
            return '#fdfd00';
        case '5':
            return '#fdfd00';
        case '4':
            return '#fdfd98';
        case '3':
            return '#00af4f';
        case '2':
            return '#91ce4f';
        case '1':
            return '#91ce4f';
        case 'L':
            return '#FF0000';
        case 'R':
            return '#FF0000';
        case 'P':
            return '#00FF00';
        case 'E':
            return '#FF0000';
        case 'A':
            return '#FFA500';
        default:
            return '';
    }
}

export const availabiltyColors = (value) => {
    switch (value) {
    case '5':
        return '#91ce4f';
    case '4':
        return '#00af4f';
    case '3':
        return '#fdfd00';
    case '2':
        return '#fd0000';
    case '1':
        return '#be0000';
    case 'L':
        return '#FF0000';
    case 'R':
        return '#FF0000';
    case 'P':
        return '#00FF00';
    case 'E':
        return '#FF0000';
    case 'A':
        return '#FFA500';
    default:
        return '';
    }
}

export const getDateRanges = (startDate, endDate) => {
    let dates = [];
    let currentDate = startDate;
    while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = currentDate.clone().add(1, 'days');
    }
    return dates;
}

export const ValueContainer = ({children, getValue, ...props}) => {
    const values = getValue();
    let valueLabel = "";

    if (values.length > 0)
        valueLabel += props.selectProps.getOptionLabel(values[0]);
    if (values.length > 1) valueLabel += ` y ${values.length - 1} más`;

    // Keep standard placeholder and input from react-select
    const childsToRender = React.Children.toArray(children).filter(
        child =>
            ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
    );

    return (
        <components.ValueContainer {...props}>
            {!props.selectProps.inputValue && valueLabel}
            {childsToRender}
        </components.ValueContainer>
    );
};