const BiomechanicIndexCalculator = (exam, player) => {

    let sagitalResults = exam.results.filter((result) => result.name.toLowerCase().includes("sagital"));

    let asimetriaRodillaSagEXT = [sagitalResults[0].values[0].value, sagitalResults[0].values[1].value];
    let asimetriaRodillaSagFLEX = [sagitalResults[1].values[0].value, sagitalResults[1].values[1].value];

    let asimetriaTobilloSagDF = [sagitalResults[2].values[0].value, sagitalResults[2].values[1].value];
    let asimetriaTobilloSagPF = [sagitalResults[3].values[0].value, sagitalResults[3].values[1].value];

    let asimetriaCaderaSagEXT = [sagitalResults[5].values[0].value, sagitalResults[5].values[1].value];
    let asimetriaCaderaSagFLEX = [sagitalResults[4].values[0].value, sagitalResults[4].values[1].value];

    let frontalResults = exam.results.filter((result) => result.name.toLowerCase().includes("frontal"));

    let asimetriaRodillaFrontalVARO = [frontalResults[0].values[0].value, frontalResults[0].values[1].value];
    let asimetriaRodillaFrontalVALGO = [frontalResults[1].values[0].value, frontalResults[1].values[1].value];

    let asimetriaCadFrontalABD = [frontalResults[2].values[0].value, frontalResults[2].values[1].value];
    let asimetriaCadFrontalADD = [frontalResults[3].values[0].value, frontalResults[3].values[1].value];

    let transverseResults = exam.results.filter((result) => result.name.toLowerCase().includes("traverso"));

    let asimetriaRodillaTraversoRMED = [transverseResults[0].values[0].value, transverseResults[0].values[1].value];
    let asimetriaRodillaTraversoRLAT = [transverseResults[1].values[0].value, transverseResults[1].values[1].value];

    return {
        asimetriaRodillaSagEXT: asimetriaRodillaSagEXT,
        asimetriaRodillaSagFLEX: asimetriaRodillaSagFLEX,
        asimetriaCaderaSagEXT: asimetriaCaderaSagEXT,
        asimetriaCaderaSagFLEX: asimetriaCaderaSagFLEX,
        asimetriaTobilloSagDF: asimetriaTobilloSagDF,
        asimetriaTobilloSagPF: asimetriaTobilloSagPF,
        asimetriaRodillaFrontalVARO: asimetriaRodillaFrontalVARO,
        asimetriaRodillaFrontalVALGO: asimetriaRodillaFrontalVALGO,
        asimetriaCadFrontalABD: asimetriaCadFrontalABD,
        asimetriaCadFrontalADD: asimetriaCadFrontalADD,
        asimetriaRodillaTraversoRMED: asimetriaRodillaTraversoRMED,
        asimetriaRodillaTraversoRLAT: asimetriaRodillaTraversoRLAT,
    }

}

export default BiomechanicIndexCalculator;