import {Navigate} from "react-router-dom";

const ProtectedRoute = (props) => {
    const { redirectPath = "/", children } = props;
    let token = localStorage.getItem("token");


    return <>{!token ? <Navigate to={redirectPath} replace /> : children}</>;
};

export default ProtectedRoute;