import React, {useEffect, useState} from "react";
import {max, median, min, mu, round} from "../../Utils/utils";
import {calculateChronicLoad, calculateSharpLoad, playerChronicLoad, playerSharpLoad} from "../../Utils/LoadCalculator";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {ResponsiveRadar} from "@nivo/radar";
import moment from "moment";
import {STATUS_ENUM} from "../../Utils/pageStatus";
import { Tooltip } from 'react-tooltip'
import TrainningChart from "../GPS_Charts/TrainningChart";
import GPSChartComponent from "../GPS_Charts/GPSChart.component";
import SharpLoadTable from "../SharpLoad/SharpLoad.component";
import RadarChart from "../RadarChart/RadarChart.component";
import TableComparison from "./TableComparison.component";
import Tabs from "../Tabs/Tabs";

const GPSComparative = ({trainingGPSData, matchGPSData}) => {

    const team = JSON.parse(sessionStorage.getItem('team'));
    const user = JSON.parse(localStorage.getItem("user"));

    let cols =
        user.company.id === "65ea346653f5498fc1401f46" ?
            [
                {name: "Distancia Total", unit: "m"},
                {name: "Distancia Relativa(m/min)", unit: "m/min"},
                {name: "Distancia Vel 18-21 km/h", unit: "m"},
                {name: "Distancia Vel 21-24 km/h", unit: "m"},
                {name: "Distancia Vel >24 km/h", unit: "m"},
                {name: "# Aceleraciones", unit: ""},
                {name: "Distancia Aceleraciones", unit: "m"},
                {name: "# Desaceleraciones", unit: ""},
                {name: "Distancia Desaceleraciones", unit: "m"},
            ] :
            [
                {name: "Distancia Total", unit: "m"},
                {name: "Distancia Relativa(m/min)", unit: "m/min"},
                {name: "# Sprints Alta Intensidad", unit: ""},
                {name: "# Desaceleraciones Alta Intensidad", unit: ""},
                {name: "High Metabolic Load Distance", unit: "m"},
                {name: "Distancia Alta Intensidad(>21)", unit: "m"},
                {name: "Distancia Sprint(>24)", unit: "m"},
                {name: "# Sprints", unit: ""},
                {name: "Smax", unit: "m/s"},
            ]

    const aggMethods = [
        {label: "Media", value: "mean", method: mu},
        {label: "Mediana", value: "median", method: median},
        {label: "Máximo", value: "max", method: max},
        {label: "Mínimo", value: "min", method: min},
    ]

    let positions = [
        {label:"Defensa"},
        {label:"Centrocampista"},
        {label:"Delantero"},
    ]


    const [status, setStatus] = useState(STATUS_ENUM.LOADING);

    const [aggMethod, setAggMethod] = useState(aggMethods[2]);
    const [positionFilter, setPositionFilter] = useState(positions);
    const [startDate, setStartDate] = useState(new Date());
    const [comparisonDates, setComparisonDates] = useState([]);
    const [availableDates, setAvailableDates] = useState([]);
    const [tooltips , setTooltips] = useState({})

    const [chartData, setChartData] = useState([]);
    const [playerChartData, setPlayerChartData] = useState({});

    useEffect(() => {
        if (trainingGPSData.length === 0 || matchGPSData.length === 0) {
            setStatus(STATUS_ENUM.EMPTY);
            return;
        }
        setStartDate(trainingGPSData[0].date.toDate())
        let matchDates = matchGPSData.map((data) => data.date.toDate())
        let matchesTooltip = matchGPSData.reduce((acc, data) => {
            let opponent = team.company.name !== data.match.team.company.name ? data.match.team.company.name : data.match.opponent.company.name
            return {...acc, [getStringDate(new Date(data.date))]: `Partido: ${opponent}`}
        }, {})
        let trainingDates = trainingGPSData.slice(1,).map((data) => data.date.toDate())
        let trainingTooltip = trainingGPSData.slice(1,).reduce((acc, data) => {
            return {...acc, [getStringDate(new Date(data.date))]: data.day_diff_to_match}
        }, {})
        setTooltips({...matchesTooltip, ...trainingTooltip})
        setAvailableDates([...matchDates, ...trainingDates])
    }, [trainingGPSData, matchGPSData])


    useEffect(() => {
        let comparisonLoadData = {};
        let trainingLoadData = {};
        if (trainingGPSData.length === 0 || matchGPSData.length === 0) {
            setStatus(STATUS_ENUM.EMPTY);
            return;
        }

        let selectedTrainingGPSData = trainingGPSData.find((data) => data.date.isSame(moment(startDate), 'day'))
        let comparisonTrainingGPSData = trainingGPSData.filter((data) => comparisonDates.find(date => moment(date).isSame(moment(data.date), 'day')))
        let comparisonMatchGPSData = matchGPSData.filter((data) => comparisonDates.find(date => moment(date).isSame(moment(data.date), 'day')))
        let selected = [...comparisonTrainingGPSData, ...comparisonMatchGPSData]

        if (selected.length === 0 || !selectedTrainingGPSData) {
            setStatus(STATUS_ENUM.EMPTY);
            return;
        }

        setStatus(STATUS_ENUM.SUCCESS)


        let chartData = []

        cols.map((col) => {
            let comparisonLoad = calculateChronicLoad(selected, aggMethod.method, col.name)
            let trainSharpLoad = calculateSharpLoad(selectedTrainingGPSData, aggMethod.method, col.name)
            comparisonLoadData[col.name] = comparisonLoad
            trainingLoadData[col.name] = trainSharpLoad

            chartData.push({
                group: col.name,
                "Fechas comparativas": 1,
                "Carga Entrenamiento": trainSharpLoad/comparisonLoad,
                comparisonLoad: comparisonLoad,
                trainSharpLoad: trainSharpLoad
            });
        })

        let playerChartData = {}
        let players = team.players;
        players.map((player) => {
            playerChartData[player.id] = {
                player: player,
                data: []
            }
            cols.map((col) => {
                let trainSharpLoad = playerSharpLoad(player, selectedTrainingGPSData, aggMethod.method, col.name)
                let comparisonLoad = playerChronicLoad(player, selected, aggMethod.method, col.name)

                playerChartData[player.id].data.push({
                    group: col.name,
                    "Fechas comparativas": 1,
                    "Carga Entrenamiento": comparisonLoad == 0 ? 0 : trainSharpLoad/comparisonLoad,
                    comparisonLoad: comparisonLoad,
                    trainSharpLoad: trainSharpLoad
                });
            })
        })

        setChartData(chartData)
        setPlayerChartData(playerChartData);
    }, [trainingGPSData, matchGPSData, aggMethod, startDate, comparisonDates]);


    let getStringDate = (date) => {
        // return ddMMyyyy
        return `D${date.getDate()}${date.getMonth() + 1}${date.getFullYear()}`
    }

    const pages = [
        {
            id: "radar-chart",
            name: "Visualización de Radar",
            component: <RadarChart chartData={chartData} playerChartData={playerChartData} positionFilter={positionFilter} tooltips={tooltips}/>
        }, {
            id: "table-chart",
            name: "Visualización de Tabla",
            component: <TableComparison
                chartData={chartData}
                playerChartData={playerChartData}
                aggMethod={aggMethod}
                selectedDate={startDate}
                comparisonDates={comparisonDates}
            />
        }
    ]


    return (
        <div className="w-100">
            <div className="row mt-3">
                <span className="h3">Comparación Carga ({startDate.toLocaleDateString("es-CL")}) contra cargas comparativas </span>
            </div>
            {status === STATUS_ENUM.LOADING && <div className="row mt-3">
                <span className="h3">Cargando datos...</span>
            </div>}
            <div className="row d-flex my-2">
                <div className="col-4">
                    <p>Fecha seleccionada</p>
                    <div className="customDatePickerWidth">
                        <DatePicker
                            className="w-100"
                            showIcon
                            selected={startDate}
                            onChange={date => {
                                setStartDate(date)
                            }}
                            includeDates={trainingGPSData.map((data) => data.date.toDate())}
                            locale="es-CL"
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                        />
                    </div>
                </div>
                <div className="col-4">
                    <p>Metodo de agregación Carga crónica</p>
                    <Select
                        placeholder="Seleccione método de agregación"
                        options={aggMethods}
                        value={aggMethod}
                        onChange={(option) => setAggMethod(option)}
                    />
                </div>
                <div className="col-4">
                    <p>Fechas comparativas</p>
                    <div className="customDatePickerWidth">
                        <DatePicker
                            className="w-100"
                            showIcon
                            placeholderText="Seleccione fechas para comparar"
                            selectedDates={comparisonDates}
                            onChange={dates => {
                                setComparisonDates(dates)
                            }}
                            dayClassName={(date) => {
                                return getStringDate(date)
                            }}
                            includeDates={availableDates}
                            selectsMultiple={true}
                            shouldCloseOnSelect={false}
                            locale="es"
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                        />
                    </div>
                </div>
            </div>
            <Tabs
                pages={pages}
            />
        </div>
    )

}

export default GPSComparative;