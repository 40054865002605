import {BACKEND_URL} from "../../constants";
import axios from "axios";
import Select from "react-select";
import React, {useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

const LoadExamComponent = ({title, description, templateURL, uploadURL, players}) => {

    const [startDate, setStartDate] = useState(new Date());
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate();


    const isAblosuteURL = (url) => {
        return url.indexOf('http') === 0;
    }

    const donwloadTemplate = (url, filename) => {
        return () => {
            let config = {
                method: 'get',
                url: `${BACKEND_URL}${url}`,
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
            };

            axios.request(config).then((response) => {
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                }
                console.log(error);
            })
        }
    }

    const uploadExam = (event) => {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("date", moment(startDate).format('X'));
        if (selectedPlayer) {
            formData.append("player", selectedPlayer.id);
        }

        let config = {
            method: 'post',
            url: `${BACKEND_URL}${uploadURL}`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        };

        axios.request(config).then((response) => {
            if (response.data.left_players && response.data.left_players.length > 0) {
                Swal.fire({
                    title: 'Datos cargados',
                    html: `<div>
                        <p>Los datos se cargaron correctamente</p>
                        <p>Los siguientes jugadores no se encuentran en la base de datos: ${response.data.left_players.join(', ')}</p>
                    </div>`,
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            } else {
                Swal.fire({
                    title: 'Datos cargados',
                    text: 'Los datos se cargaron correctamente',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
            }
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
            console.log(error);
        })
    }

    return (
        <div>
            <div className="row d-flex justify-content-between">
                <span className="h2">{title}</span>
                <span>
                    {templateURL ?
                        isAblosuteURL(templateURL) ?
                            <a href={templateURL}>Descargar
                                Template</a> :
                            <a onClick={donwloadTemplate(templateURL, `Template-${title}.xlsx`)} href="#" >Descargar Template</a>
                        :
                        null
                    }
            </span>
            </div>
            <div className="row">
                <p className="color-gray">{description} </p>
            </div>
            <div className="row">
                <h3>Subir datos</h3>
            </div>
            <div className="row">
                {players &&
                    <div className="col-12">
                        <span>Selecciona el Jugador</span>
                        <div>
                            <Select
                                placeholder="Selecciona el Jugador"
                                options={players}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(option) => setSelectedPlayer(option)}
                            />
                        </div>
                    </div>
                }
                <div className="col-12 mt-2">
                    <span>Selecciona el archivo con toda la información correspondiente</span>
                    <div>
                        <input type="file" name="file" onChange={(event) => setSelectedFile(event.target.files[0])}/>
                    </div>
                </div>
                <div className="col-12 mt-2">
                    <span>Selecciona la fecha de la toma de datos.</span>
                    <div>
                        <DatePicker
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                        />
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-end">
                <button type="button" className="btn btn-primary" onClick={uploadExam}>Cargar</button>
                </div>
            </div>
        </div>
    );
}

export default LoadExamComponent;