import moment from "moment/moment";
import {round} from "../../Utils/utils";
import React, {useEffect, useState} from "react";
import {STATUS_ENUM} from "../../Utils/pageStatus";

const TableComparison = ({ chartData, playerChartData, aggMethod, selectedDate, comparisonDates }) => {

    const user = JSON.parse(localStorage.getItem("user"));
    let positions = [
        {label:"Defensa"},
        {label:"Centrocampista"},
        {label:"Delantero"},
    ]

    let cols =
        user.company.id === "65ea346653f5498fc1401f46" ?
            [
                {name: "Distancia Total", unit: "m"},
                {name: "Distancia Relativa(m/min)", unit: "m/min"},
                {name: "Distancia Vel 18-21 km/h", unit: "m"},
                {name: "Distancia Vel 21-24 km/h", unit: "m"},
                {name: "Distancia Vel >24 km/h", unit: "m"},
                {name: "# Aceleraciones", unit: ""},
                {name: "Distancia Aceleraciones", unit: "m"},
                {name: "# Desaceleraciones", unit: ""},
                {name: "Distancia Desaceleraciones", unit: "m"},
            ] :
            [
                {name: "Distancia Total", unit: "m"},
                {name: "Distancia Relativa(m/min)", unit: "m/min"},
                {name: "# Sprints Alta Intensidad", unit: ""},
                {name: "# Desaceleraciones Alta Intensidad", unit: ""},
                {name: "High Metabolic Load Distance", unit: "m"},
                {name: "Distancia Alta Intensidad(>21)", unit: "m"},
                {name: "Distancia Sprint(>24)", unit: "m"},
                {name: "# Sprints", unit: ""},
                {name: "Smax", unit: "m/s"},
            ]

    let timeCol = user.company.id === "65ea346653f5498fc1401f46" ? "Tiempo final" : "T"

    const [status, setStatus] = useState(STATUS_ENUM.LOADING);

    const getColUnit = (col) => {
        return cols.find((c) => c.name === col).unit
    }

    useEffect(() => {
        if (chartData.length === 0) {
            setStatus(STATUS_ENUM.EMPTY)
        } else {
            setStatus(STATUS_ENUM.SUCCESS)
        }
    }, [chartData, playerChartData])

    return <div className="">
        {status === STATUS_ENUM.EMPTY &&
            <div className="row mt-5">
                <h1>No hay datos para mostrar</h1>
            </div>
        }
        {status === STATUS_ENUM.SUCCESS &&
            <>
        <div className="row mt-5">
                <h1>Tabla comparativa</h1>
        </div>
        <div className="row">
            <span>Carga {selectedDate && moment(selectedDate).format("DD/MM")} V/S Carga {aggMethod.label} ({comparisonDates && comparisonDates.map((date) =>
                moment(date).format("DD/MM")
            ).join(" y ")})</span>
        </div>
        <table className="table table-bordered table-hover">
            <thead>
            <tr>
                <th>Nombre</th>
                {cols.map((col) =>
                    <th key={col.name}>{col.name}</th>
                )}
            </tr>
            </thead>
            <tbody>
            {chartData &&
                <tr>
                    <td>Equipo General</td>
                    {chartData.map((datum) =>
                        <td key={datum.group}>
                            <p>{datum.trainSharpLoad} {getColUnit(datum.group)} V/S {datum.comparisonLoad} {getColUnit(datum.group)}</p>
                            <p></p>
                        </td>
                    )}
                </tr>
            }
            {Object.values(playerChartData).filter(data => data.player.active).map((data) =>
                <tr>
                    <td>{data.player.name}</td>
                    {data.data.map((datum) =>
                        <td key={datum.group} style={{backgroundColor: datum["Carga Entrenamiento"] > 0.75 ? "#fca4a4" : ""}}>
                            <p>{datum.trainSharpLoad} {getColUnit(datum.group)} V/S {datum.comparisonLoad} {getColUnit(datum.group)}</p>
                            <p className="text-bold">{round(datum["Carga Entrenamiento"] * 100, 0)}% de carga comparasión</p>
                        </td>
                    )}
                </tr>
            )}
            </tbody>
        </table>
            </> }
    </div>
}

export default TableComparison;