import SLABTemplate from "../../Template/SLABTemplate.component";
import moment from "moment";
import React, {useEffect, useMemo, useState} from "react";
import {BACKEND_URL} from "../../constants";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import {BallTriangle} from "react-loader-spinner";
import "./PatientsPage.styles.css";
import {Tooltip} from "react-tooltip";
import {calculateGeneralZone, getExamIndex} from "../../Utils/CheckIndexCalculator";


const PatientsPage = (props) => {

    let user = JSON.parse(localStorage.getItem("user"));

    const [checks, setChecks] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


    let getChecks = () => {
        if (false && sessionStorage.getItem('resume')) {
            let resumes = JSON.parse(sessionStorage.getItem('resume'));
            setChecks(resumes);
            setLoading(false);
        } else {
            let config = {
                method: 'get',
                url: `${BACKEND_URL}/check/resumes/last/`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
            };
            axios.request(config).then((response) => {
                setLoading(false);
                let checks = response.data.resumes;
                checks = checks.map(check => {
                    let index = getExamIndex(check);
                    return {
                        ...check,
                        indexes: index,
                    }
                });
                setChecks(checks);
                // sessionStorage.setItem('resume', checks));
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                }
                console.log(error);
            })
        }
    };


    useEffect(() => {
        setLoading(true)
        getChecks();
    }, []);


    let newChecks = checks ? checks.map(check => {
        let index = getExamIndex(check);
            return {
                ...check,
                indexes: index,
            }
        }) : null;

    if (newChecks)
        newChecks = newChecks.sort(function(a, b) { return a.score > b.score ? 1 : -1});




    return (
        <SLABTemplate title="Estado">
            <div className="row justify-content-center">
                <span className="font-weight-bold h1">Salud del plantel</span>
                <p className="text-center">Aquí podras revisar tu plantel desde un punto de vista médico . Está sección se alimenta de los datos obtenidos en nuestras dependencias y desde el plantel. </p>
                <hr/>
            </div>
            <div className="row">
                <div className="col-2">
                    <img className="w-100"  src={user.company.photo} alt="team-logo" />
                </div>
                <div className="col-10">
                    <span className="h2 color-main">{user.company.name}</span>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col">
                    <span className="h2 color-main">Jugadores</span>
                    {loading ?
                        <div className="row justify-content-center mt-5">
                            <BallTriangle
                                height={100}
                                width={100}
                                radius={5}
                                color="#011634"
                                ariaLabel="ball-triangle-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        </div> :
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Edad</th>
                                <th scope="col">Estado</th>
                                <th scope="col">Alertas</th>
                                <th scope="col">Resumen</th>
                            </tr>
                            </thead>
                            <tbody>
                            {newChecks !== null && newChecks.sort(c => c.score).map((check, index) => {

                                const getZoneColor = (zone) => {
                                    if (zone < 1) {
                                        return "green";
                                    }
                                    else if (zone >= 1 && zone <= 2) {
                                        return "yellow";
                                    } else if (zone > 2) {
                                        return "red";
                                    }
                                }

                                let {color, description} = calculateGeneralZone(check);
                                // console.log(check.user.name, check.indexes, color, description);

                                return (
                                    <tr className={getZoneColor(check.score)} key={index}>
                                        <td>{check.user.name}</td>
                                        <td>{moment().diff(moment(check.user.birthdate), 'years')} años</td>
                                        <td data-tooltip-id={`tooltip-${check.id}`}>
                                            <span>
                                                <span className={`dot ${color}`}></span>
                                                <span dangerouslySetInnerHTML={{__html: description}} />
                                            </span>
                                            <Tooltip key={`tooltip-${check.id}`} id={`tooltip-${check.id}`} place="top" effect="solid">
                                                <ul className="list-unstyled">
                                                    {
                                                        check.indexes.map((item, index) => {
                                                            // console.log(item.exam, item.score);
                                                            return <li key={`a-${index}`}>
                                                                <span
                                                                    className={`dot ${getZoneColor(item.score)}`}></span>
                                                                {item.exam}
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </Tooltip>

                                        </td>
                                        <td>
                                            <ul>
                                                {check.indexes.map(i => i.results).flat().map((item, index) => {
                                                    return (
                                                        <li key={index}>{item.name}</li>
                                                    )
                                                })}
                                            </ul>
                                        </td>
                                        <td><Link to={`/health/player/${check.user.id}/resume`} state={check}>Ver
                                            Resumen</Link></td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    }
                </div>
            </div>

        </SLABTemplate>
    );
}

export default PatientsPage;