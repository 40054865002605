const SweatIndexCalculator = (exam) => {
    let sodium = exam.results[0].values[0].value;
    let time = exam.results[1].values[0].value;
    let load = exam.results[2].values[0].value;

    return {
        sodium: sodium,
        time: time,
        load: load
    }
}

export default SweatIndexCalculator;