import React, {useEffect, useState} from "react";
import {round} from "../../Utils/utils";
import {ResponsiveRadar} from "@nivo/radar";
import {STATUS_ENUM} from "../../Utils/pageStatus";
import { Tooltip } from 'react-tooltip'

const RadarChart = ({chartData, playerChartData, positionFilter, tooltips}) => {

    const user = JSON.parse(localStorage.getItem("user"));
    let positions = [
        {label:"Defensa"},
        {label:"Centrocampista"},
        {label:"Delantero"},
    ]

    let cols =
        user.company.id === "65ea346653f5498fc1401f46" ?
            [
                {name: "Distancia Total", unit: "m"},
                {name: "Distancia Relativa(m/min)", unit: "m/min"},
                {name: "Distancia Vel 18-21 km/h", unit: "m"},
                {name: "Distancia Vel 21-24 km/h", unit: "m"},
                {name: "Distancia Vel >24 km/h", unit: "m"},
                {name: "# Aceleraciones", unit: ""},
                {name: "Distancia Aceleraciones", unit: "m"},
                {name: "# Desaceleraciones", unit: ""},
                {name: "Distancia Desaceleraciones", unit: "m"},
            ] :
            [
                {name: "Distancia Total", unit: "m"},
                {name: "Distancia Relativa(m/min)", unit: "m/min"},
                {name: "# Sprints Alta Intensidad", unit: ""},
                {name: "# Desaceleraciones Alta Intensidad", unit: ""},
                {name: "High Metabolic Load Distance", unit: "m"},
                {name: "Distancia Alta Intensidad(>21)", unit: "m"},
                {name: "Distancia Sprint(>24)", unit: "m"},
                {name: "# Sprints", unit: ""},
                {name: "Smax", unit: "m/s"},
            ]

    const [status, setStatus] = useState(STATUS_ENUM.LOADING);

    useEffect(() => {
        if (chartData.length === 0) {
            setStatus(STATUS_ENUM.EMPTY)
        } else {
            setStatus(STATUS_ENUM.SUCCESS)
        }
    }, [chartData, playerChartData])

    let getStringDate = (date) => {
        // return ddMMyyyy
        return `D${date.getDate()}${date.getMonth() + 1}${date.getFullYear()}`
    }


    return (
        <div className="w-100">


            {status === STATUS_ENUM.EMPTY && <div className="row mt-3">
                <span className="h3">No hay datos para mostrar</span>
            </div>}
            {status === STATUS_ENUM.SUCCESS &&
                <>
                    <div className="row" style={{height: "700px"}}>
                        <ResponsiveRadar
                            data={chartData}
                            keys={["Fechas comparativas", "Carga Entrenamiento"]}
                            indexBy="group"
                            maxValue="auto"
                            valueFormat={value => `${round(value * 100, 0)}%`}
                            margin={{top: 50, right: 10, bottom: 50, left: 10}}
                            isInteractive={true}
                            gridLevels={5}
                            enableDots={false}
                            legends={[
                                {
                                    anchor: 'top-left',
                                    direction: 'column',
                                    translateX: 0,
                                    translateY: -30,
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    itemTextColor: '#999',
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                            sliceTooltip={(props) => {
                                let data = chartData.find((datum) => datum.group === props.index)
                                let unit = cols.find((col) => col.name === props.index).unit
                                return <div className="card">
                                    <div className="card-header">
                                        <strong>{props.index}</strong>
                                    </div>
                                    <div className="card-body">
                                        <div>
                                            <strong>Carga
                                                Entrenamiento: </strong>{round(data.trainSharpLoad, 0)} {unit} ({round(data.trainSharpLoad * 100 / data.comparisonLoad, 0)}%
                                            con respecto a la carga de las fechas comparativas)
                                        </div>
                                        <div>
                                            <strong>Carga
                                                Comparación: </strong>{round(data.comparisonLoad, 0)} {unit}
                                        </div>
                                    </div>
                                </div>
                            }}
                        />

                    </div>
                    <div className="container">
                        {positionFilter.map((position) => {
                            let cards = Object.values(playerChartData)
                                .filter((data) => data.player.position === position.label)
                                .filter((data) => data.data.map( dtm => {
                                    return dtm["Carga Entrenamiento"]
                                }).reduce((a, b) => a + b, 0) !== 0 )
                                .map((data) => {
                                    let player = data.player
                                    let datum = data.data



                                    //search columns where the value is over 85% of the chronic load
                                    let alerts = datum.map((dtm) => {
                                        let trainSharpLoad = dtm["Carga Entrenamiento"]
                                        let group = dtm.group
                                        let unit = cols.find((col) => col.name === group).unit
                                        let alert = []
                                        // check if is not infinite

                                        if (Number.isFinite(trainSharpLoad) && trainSharpLoad > 0.85) {
                                            alert.push(`${group}: ${round(dtm.trainSharpLoad, 0)} ${unit} (${round(trainSharpLoad*100, 0)}% con respecto a la carga de comparación)`)
                                        }
                                        return {group: group, alert: alert}
                                    }
                                    ).filter((alert) => alert.alert.length > 0)

                                    return (<div className="col-6" key={player.id}>
                                        <div className="card my-3">
                                            <div className="card-header">
                                                <h5 className="text text-muted subtitle">
                                                    {player.name}
                                                </h5>
                                            </div>
                                            <div className="card-body">
                                                <div className="row w-100" style={{height: "500px"}}>
                                                    <ResponsiveRadar
                                                        data={datum}
                                                        keys={["Fechas comparativas", "Carga Entrenamiento"]}
                                                        indexBy="group"
                                                        maxValue="auto"
                                                        valueFormat={value => `${round(value * 100, 1) }%`}
                                                        margin={{top: 80, right: 80, bottom: 80, left: 80}}
                                                        isInteractive={true}
                                                        gridLevels={5}
                                                        enableDots={false}
                                                        legends={[
                                                            {
                                                                anchor: 'top-left',
                                                                direction: 'column',
                                                                translateX: -60,
                                                                translateY: -50,
                                                                itemWidth: 80,
                                                                itemHeight: 20,
                                                                itemTextColor: '#999',
                                                                symbolSize: 12,
                                                                symbolShape: 'circle',
                                                                effects: [
                                                                    {
                                                                        on: 'hover',
                                                                        style: {
                                                                            itemTextColor: '#000'
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        ]}
                                                        sliceTooltip={(props) => {
                                                            let data = datum.find((dtm) => dtm.group === props.index)
                                                            let unit = cols.find((col) => col.name === props.index).unit
                                                            return <div className="card">
                                                                <div className="card-header">
                                                                    <strong>{props.index}</strong>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div>
                                                                    <strong>Carga
                                                                        Entrenamiento: </strong>{round(data.trainSharpLoad, 0)} {unit} ({round(data.trainSharpLoad * 100 / data.comparisonLoad, 0)}%
                                                                        con respecto a la carga de las fechas comparativas)
                                                                </div>
                                                                <div>
                                                                    <strong>Carga
                                                                        Comparación: </strong>{round(data.comparisonLoad, 0)} {unit}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }}
                                                    />
                                                </div>
                                                <div className="row">
                                                    {alerts.map((alert) => {
                                                        return <div className="alert alert-danger" key={alert.group}>
                                                            <strong>Alerta: </strong>
                                                            {alert.alert.map((al) => <p key={al}>{al}</p>)}
                                                        </div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                })

                            return <div key={position.label} className="">
                                <div className="row">
                                    <h1>{position.label}</h1>
                                </div>
                                <div className="row">
                                    {cards}
                                </div>
                            </div>

                        })
                        }
                    </div>
                </>
            }
            {Object.keys(tooltips).map((date) =>{
                return <Tooltip key={date} style={{zIndex: 1000}} anchorSelect={`.${date}`} content={tooltips[date]} place="top" />
            })}
        </div>
    )

}

export default RadarChart;