const RadiometerCalculator = (exam) => {

    let pHResult = exam.results.find((result) => result.name.toLowerCase() === "ph");
    let pCO2Result = exam.results.find((result) => result.name === "pCO2");
    let baseResult = exam.results.find((result) => result.name === "cHCO3-(P)c");

    return {
        pH: pHResult.values,
        pCO2: pCO2Result.values,
        base: baseResult.values,
    }
}

export default RadiometerCalculator;
