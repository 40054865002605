import RadiometerCalculator from "../ExamIndexCalculators/RadiometerCalculator";
import {round} from "../utils";

const RadiometerStatusCalculator = (exam, player) => {
    let results = RadiometerCalculator(exam);
    let status = [];

    let flag = false;

    if (round(results.pH[1].value) > round(results.pH[0].value)){
        status.push({
            value: 3,
            recommendation: `Mala acidocis. pH sube luego de ejercicio`,
            name: "Acidosis anormal",
            dif: `Inicial: ${results.pH[0].value.toFixed(2)}, Final: ${results.pH[1].value.toFixed(2)}`,
            reference: "pH(90% carga) < ph(basal)"
        });
    }

    if (round(results.pH[1].value, 2) < 7.35) {
        flag = true;
        status.push({
            value: 1,
            recommendation: `Acidosis. pH bajo luego de ejercicio`,
            name: "Acidosis",
            dif: `Inicial: ${results.pH[0].value.toFixed(2)}, Final: ${results.pH[1].value.toFixed(2)}`,
            reference: "pH(90% carga) > 7.35",
            meaning: "Acumulación de acido o perdida de bicarbonato"
        });
    }

    if  (round(results.base[1].value) < 21)
        status.push({
            value: flag ? 1 : 0.5,
            recommendation: `Se sugiere suplementación deportiva para regular tamponamiento (Ej: beta-alanina), ver ciclos en entrenamiento y tolerancia a suplementación, para que no afecte en los días de competencia.`,
            name: "Mala respuesta metabolica",
            dif: `Inicial: ${results.base[0].value.toFixed(2)}, Final: ${results.base[1].value.toFixed(2)}`,
            reference: "BE(90% carga) > 21",
            meaning: "Acumulación en exceso de ácido"
        });

    if  (round(results.pCO2[1].value) > (results.pCO2[0].value) || results.pCO2[1].value > 45)
        status.push({
            value: flag ? 1 : 0.5,
            recommendation: `Se sugiere entrenamiento de respiración y fuelle respiratorio durante ejercicio de alta intensidad (resistencia aerobica).`,
            name: "Mala respuesta respiratoria",
            dif: `Inicial: ${results.pCO2[0].value.toFixed(2)} mmHg, Final: ${results.pCO2[1].value.toFixed(2)} mmHg`,
            reference: "pCO2(90% carga) < pCO2(basal) y pCO2(90% carga) < 45 mmHg",
            meaning: "Perdida en exceso de bicarbonato"
        });

    return status;
}

export default RadiometerStatusCalculator;