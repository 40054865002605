import * as PropTypes from "prop-types";
import {TailSpin} from "react-loader-spinner";
import {Pie, PieCanvas, ResponsivePie} from "@nivo/pie";

const TeamStatusChart = ({team}) => {

    let groups = {
        red: [],
        orange: [],
        yellow: [],
        green: [],
    }

    if (team) {
        team.forEach(player => {
            let generalZone = player.generalZone.color;
            if (generalZone === "red") {
                groups.red.push(player);
            } else if (generalZone === "orange") {
                groups.orange.push(player);
            } else if (generalZone === "yellow") {
                groups.yellow.push(player);
            } else if (generalZone === "green") {
                groups.green.push(player);
            }
        });
    }

    console.log(team)

    return (
        <div className="card">
            <div className="card-header">
                <h3>Estado del equipo</h3>
            </div>
            <div className="card-body">
                {!team ? <div className="d-flex justify-content-center">
                    <TailSpin
                        visible={true}
                        height="80"
                        width="80"
                        color="#011634"
                        ariaLabel="tail-spin-loading"
                        radius="2"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div> : <div className="row">
                    <div className="col-12">
                        <div className="row d-flex w-100" style={{height: "350px"}}>
                            <div className="col-7">
                                <h1 className="text-center h3">Distribución de riesgo del equipo</h1>
                                <div className="row" style={{height: "300px"}}>
                                    <ResponsivePie
                                        data={[
                                            {
                                                "id": "Alta",
                                                "value": groups.red.length,
                                                "color": "#ff0000"
                                            },
                                            {
                                                "id": "Media alta",
                                                "value": groups.orange.length,
                                                "color": "#ff8c00"
                                            },
                                            {
                                                "id": "Media",
                                                "value": groups.yellow.length,
                                                "color": "#ffff00"
                                            },
                                            {
                                                "id": "Baja",
                                                "value": groups.green.length,
                                                "color": "#008000"
                                            }
                                        ]}
                                        colors={({ id, data }) => data[`color`]}
                                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                    />
                                </div>
                            </div>
                            <div className="col-5 h-100 overflow-auto">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="text-center text h2">Jugadores con riesgo alto</span>
                                        <h5 className="text-center text">Alta</h5>
                                        <ul className="list-group mb-4">
                                            {groups.red.map((player, index) => {
                                                return (
                                                    <li key={index} className="list-group-item">{player.user.name}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h5 className="text-center text">Media alta</h5>
                                        <ul className="list-group">
                                            {groups.orange.map((player, index) => {
                                                return (
                                                    <li key={index} className="list-group-item">{player.user.name}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

export default TeamStatusChart;