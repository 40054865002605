import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import HTTPServices from "../../Utils/HTTPServices";
import Select from "react-select";
import moment from "moment";
import {FaCaretLeft, FaCaretRight} from "react-icons/fa";
import {getDateRanges, wellnessIsValid, loadPerseptionColor, availabiltyColors} from "../../Utils/utils";
import Swal from "sweetalert2";
import LoadingPlaceHolder from "../LoadingPlaceHolder/LoadingPlaceHolder";

const DataTable = ({players, matches, title, valueLabel, examType}) => {


    const [rangeDates, setRangeDates] = useState(getDateRanges(moment().subtract(2,'days'), moment().add(4, 'days')));
    const [matchesDates, setMatchesDates] = useState({});
    const [datum, setDatum] = useState(null);
    const [loading, setLoading] = useState(false);
    const [player, setPlayer] = useState(null);

    const team = JSON.parse(sessionStorage.getItem('team'));

    useEffect(() => {
        if (matches.length > 0) {
            setMatchesDates(matches.reduce((acc, match ) => {
                return {...acc, [match.date]: match}
            }, {}))
        }
    }, [matches]);

    const updateDatum = async () => {
        try {
            let client = new HTTPServices();
            let response = await client.getExams({
                startDate: rangeDates[0].format('YYYY-MM-DD'),
                endDate: rangeDates.slice(-1)[0].format('YYYY-MM-DD'),
                examType: examType
            })
            let data = response.data.exams;

            let dtm = players.reduce((acc, player) => {
                let tmp = {[player.id]: {}};
                let playerData = data.filter((item) => item.user._id === player.id);
                if (playerData && playerData.length > 0) {
                    playerData.forEach((item) => {
                        let date = moment(item.date);
                        let value = item.results[0].values[0].value;
                        tmp[player.id][date.format('YYYY-MM-DD')] = {[valueLabel]: value}
                    })
                }
                return {...acc, ...tmp}
            }, {})
            setDatum(dtm);

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (players.length > 0) {
            updateDatum();
        }
    }, [players, rangeDates])


    const changeDate = (increase) => {
        if (increase) {
            let lastDate = rangeDates.slice(-1)[0].clone();
            setRangeDates(getDateRanges(lastDate, lastDate.clone().add(6, 'days')));
        } else {
            let firstDate = rangeDates[0].clone();
            setRangeDates(getDateRanges(firstDate.clone().subtract(6, 'days'), firstDate));
        }
    }

    const handleChange = (event, player, date) => {
        let value = event.target.value.toUpperCase();
        if (!value || wellnessIsValid(value)) {
            let tmp  = {...datum}
            tmp[player.id][date.format('YYYY-MM-DD')] = {[valueLabel] : value};
            setDatum(tmp);
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'El valor ingresado no es válido',
                text: 'Solo puedes ingresar valores entre 1 y 10 o L en caso de lesión, P en caso de que el jugador haya ' +
                    'participado en el partido y R en caso de que el jugador este en reintegro deportivo, E en caso' +
                    'de que el jugador este enfermo o A en caso de que el jugador este ausente',
            })
        }
    }

    const sendData = () => {
        let client = new HTTPServices();
        client.createExam(datum, examType)
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Datos guardados',
                    text: 'Los datos se han guardado exitosamente'
                })
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al guardar los datos',
                    text: 'No se han podido guardar los datos, por favor intenta de nuevo'
                })
        })
    }

    return <div className="w-100 pt-3">
        <div className="row">
            <span className="h2">{title}</span>
        </div>
        <LoadingPlaceHolder loading={loading}>
            <div className="row justify-content-between mt-2">
                <button className="btn btn-outline-dark" onClick={() => changeDate(false)}>
                    <FaCaretLeft/>
                </button>

                <div className="col-2">
                    <span>Cambiar fechas</span>
                </div>

                <button className="btn btn-outline-dark" onClick={() => changeDate(true)}>
                    <FaCaretRight/>
                </button>
            </div>
            <div className="row">
                <div className="col-12">
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th scope="col" className="col-2">Jugador</th>
                            {rangeDates.map((date, index) => {
                                let isToday = date.isSame(moment(), 'day');
                                let isMatch = Object.keys(matchesDates).find((matchDate) => moment(matchDate).isSame(date, 'day'));
                                let opponent = "";
                                if (isMatch) {
                                    let match = matchesDates[isMatch];
                                    opponent = team.id === match.team.id ? match.opponent.company.name : match.team.company.name;
                                }

                                return <th key={index} scope="col" >
                                    <div className="row justify-content-center">
                                        <span className={`
                                        ${isToday ? "text-dark font-weight-bold" : "font-weight-normal"}
                                        ${isMatch ? "text-danger font-weight-bold" : "text-secondary font-weight-normal"}
                                        `}
                                        >
                                            {date.format('DD/MM')}
                                                {isMatch && <><br/>V/S {opponent}</>}
                                        </span>
                                    </div>
                                </th>
                                }
                            )}
                        </tr>
                        </thead>
                        <tbody>
                        {datum && players.filter(player => player.active).sort((a, b) => a.name > b.name ? 1 : -1 ).map((player, index) =>
                            <tr key={player.id}>
                                <td>
                                    <span>{player.name}</span>
                                    <br/>
                                    <span className="text-muted">{player.position}</span>
                                </td>
                                {rangeDates.map((date, index) => {
                                        let dtm = datum[player.id][date.format('YYYY-MM-DD')];
                                        let value = dtm ? dtm[valueLabel] : "";
                                        return <td key={`${player.id} - ${index}`}
                                                   style={{backgroundColor: examType !== "wellness" ? loadPerseptionColor(value) : availabiltyColors(value)}}>
                                            <input style={{backgroundColor: "transparent"}} type="text" className="fit-input"
                                                   name="player" value={value}
                                                   onChange={(e) => handleChange(e, player, date)}/>
                                        </td>
                                    }
                                )}
                            </tr>
                        )}
                        </tbody>
                    </table>
                    <div className="row justify-content-end">
                        <button className="btn btn-primary"
                                onClick={sendData}>Guardar
                        </button>
                    </div>
                </div>
            </div>
        </LoadingPlaceHolder>
    </div>

}

export default DataTable;