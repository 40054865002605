import LactatoIndexCalculator from "../ExamIndexCalculators/LactatoIndexCalculator";


const LactatoStatusCalculator = (exam, player) => {

    let results = LactatoIndexCalculator(exam);
    let status = [];

    if (results.lactatoValues.filter(v => v >= 4).length > 0) {
        status.push({
            value: 1,
            recommendation: `Acidosis. pH bajo luego de ejercicio`,
            name: "Acidosis",
            dif: `Maxima medición: ${Math.max(results).toFixed(2)}`,
            reference: "Lactato(90% carga) < 4",
            meaning: "Acumulación de acido o perdida de bicarbonato"
        });
    }

    return status;
}

export default LactatoStatusCalculator;
