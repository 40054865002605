import {Link, useLocation, useNavigate} from "react-router-dom";
import {getExamIndex} from "../../Utils/CheckIndexCalculator";
import moment from "moment/moment";
import examHandler from "../../ExamHandler/ExamHandler";
import {FaRegQuestionCircle} from "react-icons/fa";
import {Tooltip} from "react-tooltip";

const PatientResumeComponent = ({player}) => {

    const location = useLocation();
    const check = location.state;
    const navigate = useNavigate();
    let {recommendations, zones} = getExamIndex(check);
    console.log(check);

    const getZoneColor = (zone) => {
        if (zone >= 1 && zone <= 2){
            return "text-warning";
        } else if (zone > 2){
            return "text-danger";
        }
    }

    const getZoneIcon = (zone) => {
        if (zone < 1){
            return "";
        }
        else if (zone >= 1 && zone <= 2){
            return "-warning";
        } else if (zone > 2){
            return "-danger";
        }
    }



    return <div className="container mb-5">
        <div className="row d-flex justify-content-end mt-3">
            <img src="/images/slab-logo.jpeg" alt="SLAB logo" height="150"/>
        </div>
        <h1 className="report-title">Resumen Evaluaciones</h1>
        <hr/>
        <div className="patient-data section">
            <h2 className="section-title">Antecedentes Generales</h2>
            <div className="separation my-4"/>
            <div className="row">
                <div className="col-md-4 col-xs-12">
                    <div className="data-row">
                        <span className="data-key-label">Nombre</span>
                        <span className="data-value-label">{check.user.name}</span>
                    </div>
                    {/*<div className="data-row">*/}
                    {/*    <span className="data-key-label">Fecha Evaluación</span>*/}
                    {/*    <span className="data-value-label">{moment(check.visit.created_at).format("DD/MM/YYYY")}</span>*/}
                    {/*</div>*/}
                    <div className="data-row">
                        <span className="data-key-label">Deporte</span>
                        <span className="data-value-label">FUTBOL, {check.user.position}</span>
                    </div>
                </div>
                <div className="col-md-4 col-xs-12">
                    <div className="data-row">
                        <span className="data-key-label">Fecha Nacimiento</span>
                        <span className="data-value-label">{moment(check.user.birthdate).format("DD/MM/YYYY")}</span>
                    </div>
                    <div className="data-row">
                        <span className="data-key-label">IMC (kg/m<sup>2</sup>)</span>
                        <span
                            className="data-value-label">{(check.user.currentData.weight / (check.user.currentData.height / 100) ** 2).toFixed(0)}</span>
                    </div>
                </div>
                <div className="col-md-4 col-xs-12">
                    <div className="data-row">
                        <span className="data-key-label">Peso (Kg.)</span>
                        <span className="data-value-label">{check.user.currentData.weight}</span>
                    </div>
                    <div className="data-row">
                        <span className="data-key-label">Talla (cm)</span>
                        <span className="data-value-label">{check.user.currentData.height}</span>
                    </div>
                </div>
                <div>

                </div>
            </div>
        </div>

        <div className="patient-data section">
            <h2 className="section-title">Recomendaciones</h2>
            <button onClick={() => navigate(-1)}>Go back</button>

            <div className="separation my-4"/>
            <div className="row">

                {check.indexes.map((datum, Iindex) =>
                    <div key={`h3-${Iindex}`} className="col-12">
                        <h3 key={`title-${Iindex}`}>{datum.exam}</h3>
                        {datum.results.length > 0 ?
                            <ul>
                                {datum.results.map((result, index) =>
                                    <li key={`li-${index}`}>
                                        <span
                                            className={`text${getZoneIcon(datum.score)} font-weight-bold`}>{result.name}: </span>
                                        <span className="col-6">{result.recommendation}</span>
                                        <span className={`font-weight-bold`}>{`(${result.dif}). `}
                                            <FaRegQuestionCircle data-tooltip-id="my-tooltip"
                                                                 data-tooltip-content={`${result.meaning}. Referencia: ${result.reference}`}
                                                                 data-tooltip-place="top"/>
                                        </span>
                                        {/*<p className="font-weight-bold text-secondary">{`Referencia: ${result.reference}`}</p>*/}
                                    </li>
                                )
                                }
                            </ul> :
                            <p>No hay recomendaciones para este examen</p>
                        }
                        <Tooltip id="my-tooltip"/>
                    </div>
                )
                }
            </div>
            <div className="row justify-content-end">
                <Link to={`/health/visit/player/${check.user.id}/detail`} state={check}>Ver detalle</Link>
            </div>
        </div>

    </div>

}

export default PatientResumeComponent;