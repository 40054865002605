import {ResponsiveBar} from "@nivo/bar";
import {useState} from "react";

const BiomechanicComponent = ({exam }) => {

    const [drillDown, setDrillDown] = useState(false);

    const getAsimetria = (izqValue, derValue) => {
        let multiplier = izqValue < 0 ? -1 : 1;
        multiplier = derValue < 0 ? multiplier * -1 : multiplier;

        let max = Math.max(
            Math.abs(izqValue),
            Math.abs(derValue)
        );
        let min = Math.min(
            Math.abs(izqValue),
            Math.abs(derValue)
        );

        return Math.abs(multiplier < 0 ? 1 - min/max: min/max -1);
    }

    let cols = exam.results.map((result) => result.name);
    let rows = {};
    exam.results.forEach((result) => {
        result.values.forEach((value) => {
            if (rows[value.name] === undefined) {
                rows[value.name] = [];
            }
            rows[value.name].push(value.value) ;
        })
    });

    let sagitalResults = exam.results.filter((result) => result.name.toLowerCase().includes("sagital"));

    let asimetriaRodillaSagEXT = 100*getAsimetria(sagitalResults[0].values[0].value, sagitalResults[0].values[1].value);
    let asimetriaRodillaSagFLEX = 100*getAsimetria(sagitalResults[1].values[0].value, sagitalResults[1].values[1].value);

    let asimetriaCaderaSagEXT = 100*getAsimetria(sagitalResults[5].values[0].value, sagitalResults[5].values[1].value);
    let asimetriaCaderaSagFLEX = 100*getAsimetria(sagitalResults[4].values[0].value, sagitalResults[4].values[1].value);

    let asimetriaTobilloSagDF = 100*getAsimetria(sagitalResults[2].values[0].value, sagitalResults[2].values[1].value);
    let asimetriaTobilloSagPF = 100*getAsimetria(sagitalResults[3].values[0].value, sagitalResults[3].values[1].value);

    let frontalResults = exam.results.filter((result) => result.name.toLowerCase().includes("frontal"));

    let asimetriaRodillaFrontalVARO = 100*getAsimetria(frontalResults[0].values[0].value, frontalResults[0].values[1].value);
    let asimetriaRodillaFrontalVALGO = 100*getAsimetria(frontalResults[1].values[0].value, frontalResults[1].values[1].value);

    let asimetriaCadFrontalABD = 100*getAsimetria(frontalResults[2].values[0].value, frontalResults[2].values[1].value);
    let asimetriaCadFrontalADD = 100*getAsimetria(frontalResults[3].values[0].value, frontalResults[3].values[1].value);

    let transverseResults = exam.results.filter((result) => result.name.toLowerCase().includes("traverso"));

    let asimetriaRodillaTraversoRMED = 100*getAsimetria(transverseResults[0].values[0].value, transverseResults[0].values[1].value);
    let asimetriaRodillaTraversoRLAT = 100*getAsimetria(transverseResults[1].values[0].value, transverseResults[1].values[1].value);

    return drillDown ? <div className="row">
        <div className="col">
            <div className="row">
                <div className="col-xs-12 col-md-4">
                    <button className="btn btn-link" onClick={() => setDrillDown(!drillDown)}>Volver</button>
                </div>
            </div>
            <table className="table table-hover">
                <thead>
                <tr>
                    <th scope="col">Concepto</th>
                    {cols.map((col, index) => {
                        return (
                            <th key={index} scope="col">{col}</th>
                        )
                    })}
                </tr>
                </thead>
                <tbody>
                {Object.keys(rows).map((row, index) => {
                    return (
                        <tr key={index}>
                            <td>{row}</td>
                            {rows[row].map((value, index) => {
                                return (
                                    <td key={index}>{value}</td>
                                )
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    </div> : (<>
        <div className="row">
            <div className="col-12 d-flex justify-content-center">
                <img className="w-25" src="/images/bio.gif" alt=""/>
            </div>
            <div className="col-12">
                <h3>Plano sagital</h3>
            </div>
            <div className="col-md-6 col-xs-12" style={{minHeight: "300px"}}>
                <ResponsiveBar
                    data={[{
                        "group": "EXT",
                        "Rodilla": asimetriaRodillaSagEXT.toFixed(2),
                        "RodillaColor": "#1F4778",
                        "Cadera": asimetriaCaderaSagEXT.toFixed(2),
                        "CaderaColor": "#749BBE"
                    }, {
                        "group": "FLEX",
                        "Rodilla": asimetriaRodillaSagFLEX.toFixed(2),
                        "RodillaColor": "#1F4778",
                        "Cadera": asimetriaCaderaSagFLEX.toFixed(2),
                        "CaderaColor": "#749BBE"
                    }]}
                    onClick={() => setDrillDown(!drillDown)}
                    label={d => {
                        return `${d.id}`
                    }}
                    keys={["Rodilla", "Cadera"]}
                    groupMode="grouped"
                    indexBy="group"
                    colors={({ id, data }) => data[`${id}Color`]}
                    margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
                    labelTextColor={"#FFFFFF"}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band'}}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Grupo muscular',
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Asimetria (%)',
                        legendPosition: 'middle',
                        legendOffset: -50
                    }}
                />
            </div>
            <div className="col-md-6 col-xs-12" style={{minHeight: "300px"}}>
                <ResponsiveBar
                    data={[{
                        "group": "DF",
                        "Tobillo": asimetriaTobilloSagDF.toFixed(2),
                        "TobilloColor": "#1F4778",
                    }, {
                        "group": "PF",
                        "Tobillo": asimetriaTobilloSagPF.toFixed(2),
                        "TobilloColor": "#1F4778",
                    }]}
                    onClick={() => setDrillDown(!drillDown)}
                    label={d => {
                        return `${d.id}`
                    }}
                    keys={["Tobillo"]}
                    indexBy="group"
                    colors={({ id, data }) => data[`${id}Color`]}
                    margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
                    labelTextColor={"#FFFFFF"}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band'}}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Grupo muscular',
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Asimetria (%)',
                        legendPosition: 'middle',
                        legendOffset: -50
                    }}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <h3>Plano frontal</h3>
            </div>
            <div className="col-md-6 col-xs-12" style={{minHeight: "300px"}}>
                <ResponsiveBar
                    data={[{
                        "group": "VARO",
                        "Rodilla": asimetriaRodillaFrontalVARO.toFixed(2),
                        "RodillaColor": "#1F4778",
                    }, {
                        "group": "VALGO",
                        "Rodilla": asimetriaRodillaFrontalVALGO.toFixed(2),
                        "RodillaColor": "#1F4778",
                    }]}
                    onClick={() => setDrillDown(!drillDown)}
                    label={d => {
                        return `${d.id}`
                    }}
                    keys={["Rodilla"]}
                    indexBy="group"
                    colors={({ id, data }) => data[`${id}Color`]}
                    margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
                    labelTextColor={"#FFFFFF"}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band'}}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Grupo muscular',
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Asimetria (%)',
                        legendPosition: 'middle',
                        legendOffset: -50
                    }}
                />
            </div>
            <div className="col-md-6 col-xs-12" style={{minHeight: "300px"}}>
                <ResponsiveBar
                    data={[{
                        "group": "ABD",
                        "Cadera": asimetriaCadFrontalABD.toFixed(2),
                        "CaderaColor": "#1F4778",
                    }, {
                        "group": "ADD",
                        "Cadera": asimetriaCadFrontalADD.toFixed(2),
                        "CaderaColor": "#1F4778",
                    }]}
                    onClick={() => setDrillDown(!drillDown)}
                    label={d => {
                        return `${d.id}`
                    }}
                    keys={["Cadera"]}
                    indexBy="group"
                    colors={({ id, data }) => data[`${id}Color`]}
                    margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
                    labelTextColor={"#FFFFFF"}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band'}}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Grupo muscular',
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Asimetria (%)',
                        legendPosition: 'middle',
                        legendOffset: -50
                    }}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <h3>Plano transverso</h3>
            </div>
            <div className="col-md-12 col-xs-12" style={{minHeight: "300px"}}>
            <ResponsiveBar
                data={[{
                    "group": "RMED",
                    "Rodilla": asimetriaRodillaTraversoRMED.toFixed(2),
                    "RodillaColor": "#1F4778",
                }, {
                    "group": "RLAT",
                    "Rodilla": asimetriaRodillaTraversoRLAT.toFixed(2),
                    "RodillaColor": "#1F4778",
                }]}
                onClick={() => setDrillDown(!drillDown)}
                label={d => {
                    return `${d.id}`
                }}
                keys={["Rodilla"]}
                indexBy="group"
                colors={({ id, data }) => data[`${id}Color`]}
                margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
                labelTextColor={"#FFFFFF"}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band'}}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Grupo muscular',
                    legendPosition: 'middle',
                    legendOffset: 32
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Asimetria (%)',
                    legendPosition: 'middle',
                    legendOffset: -50
                }}
            />
        </div>
        </div>

    </>);
}

export default BiomechanicComponent;