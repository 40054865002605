import SLABTemplate from "../../Template/SLABTemplate.component";
import React, {useEffect, useState} from "react";
import HTTPServices from "../../Utils/HTTPServices";
import MatchResumeCard from "../../Components/MatchCard/MatchResumeCard.component";
import TeamStatusChartComponent from "../../Components/TeamStatusChart/TeamStatusChart.component";
import TeamStatusChart from "../../Components/TeamStatusChart/TeamStatusChart.component";
import {calculateGeneralZone, getExamIndex} from "../../Utils/CheckIndexCalculator";
import TeamStatus from "../../Components/TeamStatus/TeamStatus.component";

const DashboardPage = (props) => {

    const [team, setTeam] = useState(null);
    const [resumes, setResumes] = useState([]);

    useEffect(() => {
        let team = JSON.parse(sessionStorage.getItem("team"));
        if (team)
            setTeam(team);
    }, []);

    useEffect(() => {
        if (!team) return;
        let client = new HTTPServices();
        client.getLastResume()
            .then((response) => {
                let resumes = response.data.resumes;

                resumes = resumes.map(resume => {
                    let index = getExamIndex(resume);
                    let datum = {
                        ...resume,
                        indexes: index,
                    }
                    let generalZone = calculateGeneralZone(datum);
                    return {
                        ...datum,
                        generalZone: generalZone,
                    }
                });
                setResumes(resumes);
            })
            .catch((error) => {
                console.error(error);
            });

    },[team]);

    return (<SLABTemplate title="Dashboard">
        <div className="row justify-content-center">
            <span className="font-weight-bold h1">Dashboard</span>
            <p className="text-center">Aquí podras revisar un resumen del estado de tu plantel, con información de los jugadores, resumen de los
                analisis generados. Así como información de los futuros encuentros. </p>
            <hr/>
        </div>
        <div className="row">
            <div className="col-xs-12 col-md-6">
                <MatchResumeCard lastMatch={true} />
            </div>
            <div className="col-xs-12 col-md-6">
                <MatchResumeCard lastMatch={false} />
            </div>
        </div>
        <div className="row mt-3" >
            <div className="col-xs-12 col-md-8">
                <TeamStatusChart team={resumes}/>
            </div>
            <div className="col-xs-12 col-md-4">
                <TeamStatus resumes={resumes}/>
            </div>
        </div>
    </SLABTemplate>);
}

export default DashboardPage;