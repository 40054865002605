import RadiometerStatusCalculator from "./StatusCalculator/RadiometerStatusCalculator";
import DXAStatusCalculator from "./StatusCalculator/DXAStatusCalculator";
import IsokineticStatusCalculator from "./StatusCalculator/IsokineticStatusCalculator";
import radiometerCalculator from "./ExamIndexCalculators/RadiometerCalculator";
import UrineStatusCalculator from "./StatusCalculator/UrineStatusCalculator";
import SweatStatusCalculator from "./StatusCalculator/SweatStatusCalculator";
import LactatoStatusCalculator from "./StatusCalculator/LactatoStatusCalculator";
import BiomechanicStatusCalculator from "./StatusCalculator/BiomechanicStatusCalculator";

export const getExamIndex = (check) => {
    let datum = []

    check.sections.forEach(section => {
        section.exams.forEach(exam => {
            let examIndex = getExamStatus(exam, check.user);
            if (examIndex) datum.push(examIndex)
        })
    })



    return datum;
}

let calculateZone = (datum) => {
    let zone = datum.reduce((acc, curr) => { return acc + curr.value }, 0);
    return zone;
}

export const getExamStatus = (exam, player) => {
    if (exam.type.code === 'radiometer-venosa') {
        let results = RadiometerStatusCalculator(exam, player);
        let score = results.reduce((acc, curr) => { return acc + curr.value }, 0);
        // console.log("AB", score);
        return {
            exam: "Acido-Base",
            results: results,
            score: score
        }
    } else if (exam.type.code === 'dexa') {
        let results = DXAStatusCalculator(exam, player);
        let score = results.reduce((acc, curr) => { return acc + curr.value }, 0);
        // console.log("DXA", score);
        return {
            exam: "DXA",
            results: results,
            score: score
        }
    } else if (exam.type.code === 'biomecanica') {
        let results = BiomechanicStatusCalculator(exam, player);
        let score = results.reduce((acc, curr) => { return acc + curr.value }, 0)/4;
        // console.log("Biomecanica", score);
        return {
            exam: "Biomecanica",
            results: results,
            score: score
        }
    } else if (exam.type.code === 'isocinetica') {
        let results = IsokineticStatusCalculator(exam, player);
        let score = results.reduce((acc, curr) => { return acc + curr.value }, 0);
        if (score <= 2)
            score = 1;
        else if (score <= 4)
            score = 2;
        else if (score <= 8)
            score= 3;
        // console.log("ISO", score);
        return {
            exam: "Isocinetica",
            results: results,
            score: score
        }
    } else if (exam.type.code === 'radiometer') {
        let results = RadiometerStatusCalculator(exam, player);
        let score = results.reduce((acc, curr) => { return acc + curr.value }, 0);
        // console.log("AB", score);
        return {
            exam: "Acido-Base",
            results: results,
            score: score
        }
    } else if (exam.type.code === 'urine') {
        let results = UrineStatusCalculator(exam, player);
        let score = results.reduce((acc, curr) => { return acc + curr.value }, 0);
        // console.log("AB", score);
        return {
            exam: "Orina",
            results: results,
            score: score
        }
    }  else if (exam.type.code === 'sweat') {
        let results = SweatStatusCalculator(exam, player);
        let score = results.reduce((acc, curr) => { return acc + curr.value }, 0);
        // console.log("AB", score);
        return {
            exam: "Sudoración",
            results: results,
            score: score
        }
    } else if (exam.type.code === 'lactato') {
        let results = LactatoStatusCalculator(exam, player);
        let score = results.reduce((acc, curr) => { return acc + curr.value }, 0);
        // console.log("AB", score);
        return {
            exam: "Acido-Base",
            results: results,
            score: score
        }
    }
}


export const calculateGeneralZone = (check) => {
    let {indexes} = check;
    let dxaIndex = indexes.find(i => i.exam === "DXA");
    let acidIndex = indexes.find(i => i.exam === "Acido-Base");
    let isokineticIndex = indexes.find(i => i.exam === "Isocinetica");

    if (dxaIndex && isokineticIndex && dxaIndex.score > 2 && isokineticIndex.score > 2) {
        return {color: 'red', description: "Riesgo de lesión alto"};
    }


    if (!dxaIndex || !acidIndex || !isokineticIndex) {
        let mean = 0;
        indexes.forEach(i => mean += i.score);
        mean = mean / indexes.length;
        if (mean >= 3) {
            return {color: 'red', description: "Riesgo de lesión alto"};
        }
        if (mean > 2) {
            return {color: 'orange', description: "Riesgo de lesión alto"};
        } else if (mean >= 1) {
            return {color: 'yellow', description: "En condiciones de jugar<br>riesgo moderado de lesión"};
        } else {
            return {color: 'green', description: "En condiciones de jugar<br>riesgo bajo de lesión"};
        }
    }

    if (dxaIndex.score > 2 && isokineticIndex.score > 2) {
        return {color: 'red', description: "Riesgo de lesión alto"};
    } else if (dxaIndex.score >= 2 && isokineticIndex.score >= 1) {
        return {color: 'orange', description: "En condiciones de jugar<br>riesgo moderado de lesión"};
    } else if (dxaIndex.score >= 1 && isokineticIndex.score >= 1 && acidIndex.score >= 1) {
        return {color: 'yellow', description: "En condiciones de jugar<br>riesgo moderado de lesión"};
    } else  if (dxaIndex.score >= 1 && isokineticIndex.score >= 0) {
        return {color: 'green', description: "En condiciones de jugar<br>riesgo bajo de lesión"};
    } else  {
        return {color: 'green', description: "En condiciones de jugar<br>optimas condiciones"};
    }
}