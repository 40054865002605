import SLABTemplate from "../../Template/SLABTemplate.component";
import {useParams} from "react-router-dom";
import HTTPServices from "../../Utils/HTTPServices";
import React, {useEffect, useState} from "react";
import {BallTriangle} from "react-loader-spinner";
import moment from "moment";
import Select from "react-select";

const MatchResumePage = (props) => {

    const [matches, setMatches] = useState([]);
    const [match, setMatch] = useState(null);
    const [loading, setLoading] = useState(true);
    const { matchId } = useParams();
    const [playerData, setPlayerData] = useState([]);

    const team = JSON.parse(sessionStorage.getItem('team'));


    useEffect(() => {
        let client = new HTTPServices();
        client.getMatches()
            .then((response) => {
                let matches = response.data.matches.sort((a, b) => a.date > b.date ? 1 : -1);
                setMatches(response.data.matches);
            }).catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (matches.length === 0) {
            return
        }
        if (matchId) {
            let client = new HTTPServices();
            let match = matches.find((match) => match._id === matchId);
            if (match) {
                setMatch(match);
                setLoading(false);
            }
        }
    }, [matches, matchId]);

    useEffect(() => {
        if (!match) {
            return
        }

        let playerData = createPlayerData(match);
        setPlayerData(playerData);

    }, [match]);


    let updateMatch = () => {
        let client = new HTTPServices();
        let data = {
            resume: playerData
        }
        client.updateMatch(matchId, data)
            .then((response) => {
                console.log(response);
            }).catch((error) => {
                console.error(error);
            });
    }

    let createPlayerData = (match) => {
        if (!match || !team) {
            return []
        }
        return team.players.map((player) => {
            let playerResume = match.resume.find((resume) => resume.player.id === player.id);
            return {
                player: player,
                injured: playerResume ? playerResume.injured : false,
                description: playerResume ? playerResume.description : "",
            }
        }).sort((a, b) => a.player.name.localeCompare(b.player.name, 'en'));
    }


    return(<SLABTemplate title="Match Resume">
        <div>
            { loading ?
                <div className="row justify-content-center">
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#011634"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>:
                <>
                    <div className="row align-content-end">
                        <h1 className="text-title h2">{match.team.company.name} V/S {match.opponent.company.name}</h1>
                        <span className="h-25 align-text-bottom mt-2 ml-2" >{match.result && `${match.result}`}</span>
                    </div>
                    <div className="row d-flex">
                        <div className="col">
                            <span className="align-bottom">{moment(match.date).format("DD/MM/YYYY")}</span>
                        </div>
                        <div className="col">
                            <Select
                                placeholder="Seleccione encuentro"
                                options={matches}
                                getOptionLabel={(option) => `${option.team.company.name} V/S ${option.opponent.company.name} (${moment(option.date).format("DD/MM/YYYY")})`}
                                getOptionValue={(option) => option._id}
                                value={match}
                                onChange={(option) => {
                                    setMatch(option)
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <h2 className="h3">Estado jugadores</h2>
                        <table className="table ">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Posición</th>
                                <th>Lesion</th>
                                <th>Comentario</th>
                            </tr>
                        </thead>
                        <tbody>
                            {playerData && playerData.map((resume, index) =>
                                <tr key={match._id + index}>
                                    <td>{resume.player.name}</td>
                                    <td>{resume.player.position}</td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            value={resume.injured || false}
                                            onChange={(e) => {
                                                let newPlayerData = playerData;
                                                newPlayerData[index].injured = e.target.checked;
                                                setPlayerData(newPlayerData);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <textarea
                                            className="w-100"
                                            rows={3}
                                            defaultValue={resume.description}
                                            onChange={(e) => {
                                                let newPlayerData = playerData;
                                                newPlayerData[index].description = e.target.value;
                                                setPlayerData(newPlayerData);
                                            }}
                                        >
                                        </textarea>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="row d-flex justify-content-end mx-3">
                        <button onClick={updateMatch} className="btn btn-primary">Guardar</button>
                    </div>
                </div>
                </>
            }
        </div>
    </SLABTemplate>)
}

export default MatchResumePage;
