import {ResponsiveBar} from "@nivo/bar";
import {useState} from "react";

const IsokineticExamComponent = ({exam, user}) => {

    let [drillDown, setDrillDown] = useState(false);
    let [normalized, setNormalized] = useState(false);

    let torqueResult = exam.results.find((result) => result.name.includes("Torque"));


    let timeResult = exam.results.find((result) => result.name.includes("Tiempo"));

    let QDer = torqueResult.values.find((value) => value.name.includes("Quadricep Der")).value;
    let QIzq = torqueResult.values.find((value) => value.name.includes("Quadricep Izq")).value;
    let IDer = torqueResult.values.find((value) => value.name.includes("Isquio Der")).value;
    let IIzq = torqueResult.values.find((value) => value.name.includes("Isquio Izq")).value;


    let QDerTime = timeResult.values.find((value) => value.name.includes("Quadricep Der")).value;
    let QIzqTime = timeResult.values.find((value) => value.name.includes("Quadricep Izq")).value;
    let IDerTime = timeResult.values.find((value) => value.name.includes("Isquio Der")).value;
    let IIzqTime = timeResult.values.find((value) => value.name.includes("Isquio Izq")).value;

    let RelDer =  IDer / QDer;
    let RelIzq =  IIzq / QIzq;

    let QuadRatio =  100 * ((Math.max(QDer, QIzq) / Math.min(QDer, QIzq)) - 1);
    let IzqRatio =  100 * ((Math.max(IDer, IIzq) / Math.min(IDer, IIzq)) - 1);

    let weight = user.currentData.weight

    return <div className="row">
        {
            drillDown ?
                <div className="col-xs-12 col-md-6" style={{height: "350px"}}>
                    <div className="row d-flex justify-content-right">
                        <div className="col form-check">
                            <input className="form-check-input" type="checkbox" checked={normalized} onChange={() => setNormalized(!normalized)}/>
                            <label className="form-check-label" >Normalizar valores con peso</label>

                        </div>
                    </div>
                    <ResponsiveBar
                        onClick={() => setDrillDown(!drillDown)}
                        data={[{
                            "group": "Cuad. Der.",
                            "Fuerza": normalized ? (QDer/weight).toFixed(2) : QDer,
                            "FuerzaColor": "#1F4778"
                        }, {
                            "group": "Cuad. Izq.",
                            "Fuerza": normalized ? (QIzq/weight).toFixed(2) : QIzq,
                            "FuerzaColor": "#1F4778"
                        }, {
                            "group": "Isq. Der.",
                            "Fuerza": normalized ? (IDer/weight).toFixed(2) : IDer,
                            "FuerzaColor": "#749BBE"
                        }, {
                            "group": "Isq. Izq.",
                            "Fuerza": normalized ? (IIzq/weight).toFixed(2) : IIzq,
                            "FuerzaColor": "#749BBE"
                        }]}
                        keys={["Fuerza"]}
                        indexBy="group"
                        colors={({ id, data }) => data[`${id}Color`]}
                        margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
                        labelTextColor={"#FFFFFF"}
                        padding={0.3}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Grupo muscular',
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: normalized ? 'Torque relativo (NM/Kg)' : 'Torque (NM)',
                            legendPosition: 'middle',
                            legendOffset: -40
                        }}
                    />
                </div> :
                <>
                    <div className="col-12 d-flex justify-content-center">
                        <img className="w-25" src="/images/iso.gif" alt=""/>
                    </div>
                <div className="col-xs-12 col-md-4" style={{height: "350px"}}>
                    <ResponsiveBar
                        onClick={() => setDrillDown(!drillDown)}
                        data={[{
                            "group": "Relación Q/I Der",
                            "Fuerza": RelDer.toFixed(2),
                            "FuerzaColor": "#1F4778"
                        }, {
                            "group": "Relación Q/I Izq",
                            "Fuerza": RelIzq.toFixed(2),
                            "FuerzaColor": "#749BBE"
                        }, ]}
                        keys={["Fuerza"]}
                        indexBy="group"
                        margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
                        labelTextColor={"#FFFFFF"}
                        padding={0.3}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={({ id, data }) => data[`${id}Color`]}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Relación',
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Ratio',
                            legendPosition: 'middle',
                            legendOffset: -40
                        }}
                    />
                </div>
                    <div className="col-xs-12 col-md-4" style={{height: "350px"}}>
                        <ResponsiveBar
                            onClick={() => setDrillDown(!drillDown)}
                            data={[{
                                "group": "Deficit Quadriceps",
                                "Deficit": QuadRatio.toFixed(2),
                                "DeficitColor": "#1F4778"
                            }, {
                                "group": "Deficit Isquiotibiales",
                                "Deficit": IzqRatio.toFixed(2),
                                "DeficitColor": "#749BBE"
                            }, ]}
                            keys={["Deficit"]}
                            indexBy="group"
                            margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            label={d => `${d.value} %`}
                            labelTextColor={"#FFFFFF"}
                            colors={({ id, data }) => data[`${id}Color`]}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: 32
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Deficit (%)',
                                legendPosition: 'middle',
                                legendOffset: -40
                            }}
                        />
                    </div>
                </>
        }
        <div className={`col-xs-12 ${drillDown ? "col-md-6 mt-4" : "col-md-4"}`} style={{height: "350px"}}>
            <ResponsiveBar
                data={[{
                    "group": "Cuad. Der.",
                    "Tiempo": QDerTime,
                    "TiempoColor": "#1F4778"
                }, {
                    "group": "Cuad. Izq.",
                    "Tiempo": QIzqTime,
                    "TiempoColor": "#1F4778"
                }, {
                    "group": "Isq. Der.",
                    "Tiempo": IDerTime,
                    "TiempoColor": "#749BBE"
                }, {
                    "group": "Isq. Izq.",
                    "Tiempo": IIzqTime,
                    "TiempoColor": "#749BBE"
                }]}
                keys={["Tiempo"]}
                indexBy="group"
                colors={({ id, data }) => data[`${id}Color`]}
                margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
                labelTextColor={"#FFFFFF"}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Grupo muscular',
                    legendPosition: 'middle',
                    legendOffset: 32
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Tiempo (s)',
                    legendPosition: 'middle',
                    legendOffset: -50
                }}
            />
        </div>
    </div>
}

export default IsokineticExamComponent;