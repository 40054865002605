const SweatExamComponent = ({exam}) => {

    let sodium = exam.results[0].values[0].value;
    let time = exam.results[1].values[0].value;
    let load = exam.results[2].values[0].value;

    const CARGA = {
        1: "Carga baja",
        2: "Carga media",
        3: "Carga alta"
    }

    return (
        <div className="row">
            <div className="col-md-4 col-xs-12">
                <div className="card mt-3">
                    <div className="card-header">
                        <h5 className="card-title">Sodio peridido</h5>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">{sodium.toFixed(0)} {exam.results[0].unit}</h5>
                    </div>
                </div>
            </div>

            <div className="col-md-4 col-xs-12">
                <div className="card mt-3">
                    <div className="card-header">
                        <h5 className="card-title">Tiempo de carga</h5>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">{time.toFixed(0)} {exam.results[1].unit}</h5>
                    </div>
                </div>
            </div>


            <div className="col-md-4 col-xs-12">
                <div className="card mt-3">
                    <div className="card-header">
                        <h5 className="card-title">Tipo de carga</h5>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">{CARGA[load]}</h5>
                    </div>
                </div>
            </div>

        </div>);

}

export default SweatExamComponent;