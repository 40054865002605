import React, {useEffect, useState} from "react";

import SLABTemplate from "../../Template/SLABTemplate.component";
import {BACKEND_URL} from "../../constants";
import axios from "axios";
import LoadExamComponent from "../../Components/LoadExam/LoadExam.component";
import {useNavigate} from "react-router-dom";
import Tabs from "../../Components/Tabs/Tabs";
import Swal from "sweetalert2";
import HTTPServices from "../../Utils/HTTPServices";

const ExamUploadComponent = (props) => {

    const [loading, setLoading] = useState(true);
    const [teams, setTeams] = useState(null);
    const [team, setTeam] = useState(null);
    const [tab, setTab] = useState('clinical');
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'SLAB - Plantel';
        const getTeams = async () => {
            setLoading(true)
            let config = {
                method: 'get',
                url: `${BACKEND_URL}/teams/`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
            };

            axios.request(config).then((response) => {
                setLoading(false)
                // console.log(response);
                setTeams(response.data.teams);
            }).catch((error) => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                }
                console.log(error);
            })

        }
        getTeams();
    }, []);

    useEffect(() => {
        const getTeam = async (teamId) => {
            setLoading(true)
            let config = {
                method: 'get',
                url: `${BACKEND_URL}/teams/${teamId}/`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
            };

            axios.request(config).then((response) => {
                setLoading(false)
                // console.log(response);
                setTeam(response.data);
            }).catch((error) => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                }
                console.log(error);
            })
        }


        if (teams !== null && teams.length) {
            let team = teams[0];
            document.title = 'SLAB - Plantel - ' + team.name;
            getTeam(team.id);
        }
    }, [teams]);


    return <SLABTemplate title="exam_upload">
        <div className="row justify-content-center">
            <span className="font-weight-bold h1">Carga de exámenes</span>
            <p className="text-center">Aquí podras cargar los resultados de los exámenes, descarga los templates,
                rellena los resultados y cargalos en la plataforma. </p>
            <hr/>
        </div>
        {loading ? <div className="row justify-content-center">
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            </div> : <div>


            <Tabs
                pages={[
                    {
                        id: "clinical",
                        name: "Clínicos",
                        component:  <LoadExamComponent
                            title="Exámenes Clínicos"
                            description="Carga los exámenes clínicos de los jugadores"
                            templateURL="https://slab-webpage.s3.sa-east-1.amazonaws.com/Template+Resultados+exa%CC%81menes+clinicos.xlsx"
                            uploadURL="/check/clinical/"
                            players={team ? team.players.filter(player => player.role.name === "player") : []}
                        />
                    },{
                        id: "urine",
                        name: "Orina",
                        component: <LoadExamComponent
                            title="Exámen de Orina"
                            description="Carga los exámenes de orina de los jugadores"
                            templateURL="/check/urine/template/"
                            uploadURL="/check/urine/"
                        />
                    },{
                        id: "sweat",
                        name: "Sudor",
                        component: <LoadExamComponent
                            title="Exámen de Sudor"
                            description="Carga los exámenes de sudor de los jugadores"
                            templateURL="/check/sweat/template/"
                            uploadURL="/check/sweat/"
                        />
                    }, {
                        id: "Sincronizaciones",
                        name: "Otros",
                        component: <div>
                            <div className="row">
                                <button className="btn btn-primary my-2" onClick={() => {
                                    let client = new HTTPServices();
                                    client.createResume()
                                        .then((response) => {
                                            Swal.fire({
                                                title: 'Sincronización de partidos',
                                                text: 'Los partidos se sincronizaron correctamente',
                                                icon: 'success',
                                            });
                                        }).catch((error) => {
                                        console.error(error);
                                        Swal.fire({
                                            title: 'Sincronización de partidos',
                                            text: 'Hubo un error al sincronizar los partidos',
                                            icon: 'error',
                                        });
                                    })
                                }}>Crear resumen jugadores
                                </button>
                            </div>
                            <div className="row">
                                <button className="btn btn-primary my-2" onClick={() => {
                                    let client = new HTTPServices();
                                    client.syncMatches("2211")
                                        .then((response) => {
                                            Swal.fire({
                                                title: 'Sincronización de partidos',
                                                text: 'Los partidos se sincronizaron correctamente',
                                                icon: 'success',
                                            });
                                        }).catch((error) => {
                                        console.error(error);
                                        Swal.fire({
                                            title: 'Sincronización de partidos',
                                            text: 'Hubo un error al sincronizar los partidos',
                                            icon: 'error',
                                        });
                                    })
                                }}>Actualizar Partidos
                                </button>
                            </div>
                            <div className="row">
                                <button className="btn btn-primary my-2" onClick={() => {
                                    let client = new HTTPServices();
                                    client.syncTeams()
                                        .then((response) => {
                                            Swal.fire({
                                                title: 'Sincronización de equipos',
                                                text: 'Los equipos se sincronizaron correctamente',
                                                icon: 'success',
                                            });
                                        }).catch((error) => {
                                        console.error(error);
                                        Swal.fire({
                                            title: 'Sincronización de equipos',
                                            text: 'Hubo un error al sincronizar los equipos',
                                            icon: 'error',
                                        });
                                    })
                                }}>Actualizar Equipos
                                </button>
                            </div>
                        </div>
                    }]
                }/>
        </div>}
    </SLABTemplate>
}

export default ExamUploadComponent;