import DXAIndexCalculator from "../ExamIndexCalculators/DXAIndexCalculator";

const DXAStatusCalculator = (exam, player) => {
    let results = DXAIndexCalculator(exam);
    let status = []

    let bmi = results.totalFat / results.totalWeight;
    let bli = results.totalLean / Math.pow(player.currentData.height / 100, 2);
    let bfi = results.totalFat / Math.pow(player.currentData.height / 100, 2);

    // console.log(player.name, results)

    if (bmi > 13)
        status.push({
            value: 1.5,
            recommendation: "Porcentaje de grasa elevado para la competencia deportiva, se sugiere entrenamiento endurance para favorecer oxidación de ácidos graso (2-3 veces por semana), en modalidad 1)LIS (baja intensidad estado estacionario) en condición de ayuna con duración de 60 minutos; 2)HIIT (entrenamiento de alta intensidad con intervalos) ciclo de alta intensidad 2 minutos (↑75%) y baja de 4 minutos (↓45%) durante 45 minutos; o 3)MICT (moderado continuo) en jornada pm , luego de entrenamiento AM, 45-60 minutos constantes a una carga cercana al 60% (el porcentaje se puede asociar a carga externa (GPS) o carga interna (FC)).",
            name: "Porcentaje de grasa elevado",
            dif: `${bmi.toFixed(1)} %`,
            reference: "13%",
            meaning: "Porcentaje de grasa corporal"
        });

    if (bfi > 2.4)
        status.push({
            value: 1.5,
            recommendation: "Indice de masa grasa elevado para la competencia deportiva, se sugiere entrenamiento endurance para favorecer oxidación de ácidos graso (2-3 veces por semana), en modalidad 1)LIS (baja intensidad estado estacionario) en condición de ayuna con duración de 60 minutos; 2)HIIT (entrenamiento de alta intensidad con intervalos) ciclo de alta intensidad 2 minutos (↑75%) y baja de 4 minutos (↓45%) durante 45 minutos; o 3)MICT (moderado continuo) en jornada pm , luego de entrenamiento AM, 45-60 minutos constantes a una carga cercana al 60% (el porcentaje se puede asociar a carga externa (GPS) o carga interna (FC)).",
            name: "Indice de grasa elevado",
            dif: bfi.toFixed(1),
            reference: "2.4",
            meaning: "Relación entre la masa grasa y la altura al cuadrado"
        });

    if (bli < 20.4)
        status.push({
            value: 1,
            recommendation: "Índice  de masa magra bajo para la competencia deportiva. Se recomienda estrategia nutricional para aumentar masa magra, además de entrenamiento orientado a la hipertrofia muscular (conservando perfil fuerza - velocidad durante los entrenamiento) ver análisis segmentario, considerar distancia de al menos 48 horas previo a la competencia.",
            name: "Indice de masa magra bajo",
            dif: bli.toFixed(1),
            reference: "20.4",
            meaning: "Relación entre la masa magra y la altura al cuadrado"
        });

    return status;
}

export default DXAStatusCalculator;