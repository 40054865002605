import React from "react";

const Tabs = ({pages}) => {

    const [tab, setTab] = React.useState(pages[0].id);

    return (
        <div className="w-100">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                {pages.map((page, index) =>
                    <li className="nav-item" role="presentation" key={page.id}>
                        <button className={`nav-link ${tab === page.id ? "active" : ""}`} id={`${page.id}tab`}
                                data-bs-toggle="tab"
                                data-bs-target={`#${page.id}`} type="button" role="tab" aria-controls={`${page.id}`}
                                aria-selected="true" onClick={() => setTab(`${page.id}`)}
                        >{page.name}
                        </button>
                    </li>
                )}
            </ul>
            <div className="tab-content" id="myTabContent">
                {pages.map((page, index) =>
                    <div className={`tab-pane fade ${tab === page.id ? "show active" : ""}`} key={`content-${page.id}`} id={page.id}
                            role="tabpanel"
                            aria-labelledby={`${page.id}-tab`}>
                        {page.component}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Tabs;