import {getDateRanges, getTspanGroups, mu, round, thousandsSeparator} from "../../Utils/utils";
import {ResponsiveBar} from "@nivo/bar";
import React, {useEffect, useState} from "react";
import HTTPServices from "../../Utils/HTTPServices";
import moment from "moment/moment";
import Select from "react-select";
import {FaCaretLeft, FaCaretRight} from "react-icons/fa";
import LoadingPlaceHolder from "../LoadingPlaceHolder/LoadingPlaceHolder";
import {unmountComponentAtNode} from "react-dom";

const LoadAvailabilityReport = ({setHideReport}) => {

    const team = JSON.parse(sessionStorage.getItem('team'));
    const [rangeDates, setRangeDates] = useState(getDateRanges(moment().subtract(4,'days'), moment().add(4, 'days')));
    const [loadData, setLoadData] = useState(null);
    const [availabilityData, setAvailabilityData] = useState(null);
    const [matches, setMatches] = useState(null);
    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [player, setPlayer] = useState(null);
    const [dataLoadedRange, setDataLoadedRange] = useState(null);

    const shouldLoadData = () => {
        if (dataLoadedRange === null) {
            return true;
        }
        let startDate = rangeDates[0];
        let endDate = rangeDates.slice(-1)[0];
        return startDate.isBefore(dataLoadedRange[0]) || endDate.isAfter(dataLoadedRange[1]);

    }

    useEffect(() => {
        const loadWellnessLoadData = async () => {
            if (!shouldLoadData())
                return;
            try {
                let client = new HTTPServices();

                let matchesResponse = await client.getMatches();
                setMatches(matchesResponse.data.matches);
                let startDate = dataLoadedRange ? dataLoadedRange[0].clone().subtract(5, "d").startOf("month") : rangeDates[0].clone().startOf("month");
                let endDate = dataLoadedRange ? dataLoadedRange[1].clone().add(5, "d").endOf("month") : rangeDates.slice(-1)[0].clone().endOf("month");

                let [wellnessResponse, loadResponse] = await Promise.all(
                    [client.getExams({
                        startDate: startDate.format('YYYY-MM-DD'),
                        endDate: endDate.format('YYYY-MM-DD'),
                        examType: "wellness"
                    }), client.getExams({
                        startDate: startDate.format('YYYY-MM-DD'),
                        endDate: endDate.format('YYYY-MM-DD'),
                        examType: "subjective_load"
                    })]);

                let wellnessData = wellnessResponse.data.exams;
                let loadData = loadResponse.data.exams;
                setAvailabilityData(wellnessData);
                setLoadData(loadData);
                setDataLoadedRange([startDate, endDate]);
            } catch (error) {
                console.log(error)
            }
        }

        loadWellnessLoadData();
    }, [rangeDates]);

    useEffect(() => {
        if (loadData !== null && availabilityData !== null && matches !== null) {
            setLoading(true)
            setChartData([]);
            const chartData = [];
            rangeDates.forEach((date) => {
                let dateData = {}

                // If theres matches on the date get the match
                let match = matches.find((match) => moment(match.date).isSame(date, "day"));

                if (match) {
                    dateData.group = `${date.format('DD/MM')} - ${match.team._id === team.id ? match.opponent.company.name : match.team.company.name}`;
                } else {
                    dateData.group = date.format('DD/MM');
                }

                let sourceData = [availabilityData, loadData];
                if (player) {
                    sourceData = sourceData.map((source) => {
                        return source.filter((item) => item.user._id === player.id);
                    })
                }

                // console.log(loadData.map((item) => item.date))

                metrics.forEach((metric) => {
                    sourceData.forEach((source) => {

                        let data = source.filter((item) => moment(item.date).isSame(date, "day") && item.type.code === metric.key);
                        if (!dateData[metric.name]) {
                            dateData[metric.name] = [];
                        }
                        if (data && data.length > 0) {
                            let values = data
                                .map((item) => {
                                    return item.results[0].values[0].value;
                                })
                                .map((value) => parseFloat(value))
                                .filter((value) => !Number.isNaN(value));

                            dateData[metric.name] = round(mu(values), 1);
                        }
                    })
                })
                chartData.push(dateData);
            })
            setChartData(chartData);
            setLoading(false);
        }
    }, [loadData, availabilityData, player, matches, rangeDates]);

    const changeDate = (increase) => {
        let firstDate = rangeDates[0].clone();
        let lastDate = rangeDates.slice(-1)[0].clone();
        if (increase) {
            setRangeDates(getDateRanges(firstDate.clone().add(1, "days"), lastDate.clone().add(1, 'days')));
        } else {
            setRangeDates(getDateRanges(firstDate.clone().subtract(1, 'days'), lastDate.clone().subtract(1, 'days')));
        }

    }

    const metrics = [
        {
            name: "Carga Subjetiva",
            key: "subjective_load"
        },
        {
            name: "Disponibilidad",
            key: "wellness"
        }
    ]

    return (
        <div>
            <div className="row mt-3">
                <span className="h3">Carga Subjetiva v/s Disponibilidad</span>
            </div>
            <LoadingPlaceHolder loading={loading}>
                <div className="row my-2 ">
                    <div className="col-3">
                        <Select
                            placeholder="Seleccione jugador"
                            closeMenuOnSelect={true}
                            isClearable
                            options={team.players}
                            getOptionLabel={(option) => `${option.name}`}
                            getOptionValue={(option) => option.id}
                            value={player}
                            onChange={(option) => setPlayer(option)}
                        />
                    </div>
                </div>
                <div className="row justify-content-between mt-2">
                    <button className="btn btn-outline-dark" onClick={() => changeDate(false)}>
                        <FaCaretLeft/>
                    </button>

                    <div className="col-2">
                        <span>Cambiar fechas</span>
                    </div>

                    <button className="btn btn-outline-dark" onClick={() => changeDate(true)}>
                        <FaCaretRight/>
                    </button>
                </div>
                <div className="row" style={{height: "600px"}}>
                    <ResponsiveBar
                        data={chartData}
                        keys={metrics.map((metric) => metric.name)}
                        indexBy="group"
                        groupMode="grouped"
                        margin={{top: 50, right: 130, bottom: 50, left: 90}}
                        padding={0.3}
                        valueFormat={value => `${value}`}
                        axisBottom={{
                            renderTick: ({
                                             opacity,
                                             textAnchor,
                                             textBaseline,
                                             textX,
                                             textY,
                                             theme,
                                             value,
                                             x,
                                             y
                                         }) => {
                                return (
                                    <g
                                        transform={`translate(${x},${y})`}
                                        style={{opacity}}
                                    >
                                        <text
                                            alignmentBaseline={textBaseline}
                                            style={{fontSize: "12px"}}
                                            textAnchor={textAnchor}
                                            transform={`translate(${textX},${textY})`}
                                        >
                                            {getTspanGroups(value)}
                                        </text>
                                    </g>
                                )
                            }
                        }}
                        axisLeft={{
                            format: value => `${value}`,
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: `Valor`,
                            legendPosition: 'middle',
                            legendOffset: -70
                        }}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                        isInteractive={true}
                    />
                </div>
            </LoadingPlaceHolder>
        </div>)
}

export default LoadAvailabilityReport;