import IsokineticCalculator from "../ExamIndexCalculators/IsokineticCalculator";

const IsokineticStatusCalculator = (exam, player) => {
    let results = IsokineticCalculator(exam);
    let status = []
    let weight = player.currentData.weight;

    // console.log("ISO", results);

    let mult = 1;


    if (results.RelDer < 0.6 || results.RelIzq < 0.6){
        mult = results.RelDer < 0.6 && results.RelIzq <0.6 ? 3 : 2;
        status.push({
            value: 1 * mult,
            recommendation: "Se sugiere optimizar fuerza muscular  para balances óptimos. Entrenamiento de fuerza, ajustar cargas según competencia.",
            name: "Desbalance muscular bilateral",
            dif: results.RelDer < 0.6 ? results.RelDer.toFixed(1) : results.RelIzq.toFixed(1),
            reference: "Relación de torque extensor/flexor > 60%",
            meaning: "Relación de torque extensor/flexor",
        });

        if (results.QDer/weight < 3.17 || results.QIzq/weight < 3.17)
            status.push({
                value: 1 * mult,
                recommendation: "Deficit de torque extensor, se sugiere entrenamiento de fuerza muscular 2-3 veces por semana (considerar %1RM) con una distancia de 48 horas previo al juego, para evitar daño muscular y disminución de rendimiento.",
                name: "Deficit de torque extensor",
                dif: `PT/BW: ${results.QDer/weight < 3.17 ? (results.QDer/weight).toFixed(1) : (results.QIzq/weight).toFixed(1)}`,
                reference: "PT/BW > 3.17",
                meaning: "Relación de torque extensor con respecto a la masa corporal",
            });

        if (results.IDer/weight < 1.78 || results.IIzq/weight < 1.78)
            status.push({
                value: 1 * mult,
                recommendation: "Deficit de torque flexor, se sugiere entrenamiento de fuerza muscular 2-3 veces por semana (considerar %1RM)con una distancia de 48 horas previo al juego, para evitar daño muscular y disminución de rendimiento. ",
                name: "Deficit de torque flexor",
                dif: `PT/BW: ${results.IDer/weight < 1.78 ? (results.IDer/weight).toFixed(1) : (results.IIzq/weight).toFixed(1)}`,
                reference: "PT/BW > 1.78",
                meaning: "Relación de torque flexor con respecto a la masa corporal",
            });
    } else {
        if (results.QDer/weight < 3.17 || results.QIzq/weight < 3.17)
            status.push({
                value: 1 * mult,
                recommendation: "Deficit de torque extensor, se sugiere entrenamiento de fuerza muscular 2-3 veces por semana (considerar %1RM) con una distancia de 48 horas previo al juego, para evitar daño muscular y disminución de rendimiento.",
                name: "Deficit de torque extensor",
                dif: `PT/BW: ${results.QDer/weight < 3.17 ? (results.QDer/weight).toFixed(1) : (results.QIzq/weight).toFixed(1)}`,
                reference: "PT/BW > 3.17",
                meaning: "Relación de torque extensor con respecto a la masa corporal",
            });
        if (results.IDer/weight < 1.78 || results.IIzq/weight < 1.78)
            status.push({
                value: 1 * mult,
                recommendation: "Deficit de torque flexor, se sugiere entrenamiento de fuerza muscular 2-3 veces por semana (considerar %1RM)con una distancia de 48 horas previo al juego, para evitar daño muscular y disminución de rendimiento. ",
                name: "Deficit de torque flexor",
                dif: `PT/BW: ${results.IDer/weight < 1.78 ? (results.IDer/weight).toFixed(1) : (results.IIzq/weight).toFixed(1)}`,
                reference: "PT/BW > 1.78",
                meaning: "Relación de torque flexor con respecto a la masa corporal",
            });
    }


    if (results.QuadRatio > 5)
        status.push({
            value: 1 * mult,
            recommendation: `Asimetria muscular de cuadriceps, aumentar torque extensor ${results.QIzq > results.QDer ? "derecho" : "izquierdo" }. Se sugiere contracción muscular aislada, unilateral y control de fase excéntrica durante el ejercicio (ej: silla de cuadriceps, 75%1RM, subir con ambas, bajar solo con extremidad objetivo, dosificar para fuerza muscular).`,
            name: "Asimetria muscular de cuadriceps",
            dif: `${results.QuadRatio.toFixed(1)} %`,
            reference: "Asimetria muscular de cuadriceps < 5%",
            meaning: "Asimetria muscular de entre cuadriceps derecho e izquierdo",
        });

    if (results.IzqRatio > 5)
        status.push({
            value: 1 * mult,
            recommendation: `Asimetria muscular de isquiotibiales, aumentar torque flexor ${results.IIzq > results.IDer ? "derecho" : "izquierdo" }. Se sugiere contracción muscular aislada, unilateral y control de fase excéntrica durante el ejercicio (ej: silla de cuadriceps, 75%1RM, subir con ambas, bajar solo con extremidad objetivo, dosificar para fuerza muscular).`,
            name: "Asimetria muscular de isquiotibiales",
            dif: `${results.IzqRatio.toFixed(1)} %`,
            reference: "Asimetria muscular de isquiotibiales < 5%",
            meaning: "Asimetria muscular de entre isquiotibiales derecho e izquierdo",
        });

    return status;

}

export default IsokineticStatusCalculator;