

const DXAIndexCalculator = (exam, player) => {
    let cols = exam.results.map((result) => result.name);
    let rows = {};
    exam.results.forEach((result) => {
        result.values.forEach((value) => {
            if (rows[value.name] === undefined) {
                rows[value.name] = [];
            }
            rows[value.name].push(value.value) ;
        })
    });

    let maxFatZone = "";
    let maxFatZonePercentage = 0;
    Object.keys(rows).map((key) => {
        if (key === "Total") return;
        if (rows[key][0] > maxFatZonePercentage) {
            maxFatZonePercentage = rows[key][0];
            maxFatZone = key;
        }
    })


    let totalLeanIndex = 3;
    let totalFatIndex = 2;
    let totalWeightIndex = 1;

    let totalLean = rows["Total"][totalLeanIndex];
    let totalFat = rows["Total"][totalFatIndex];
    let totalWeight = rows["Total"][totalWeightIndex];

    if (totalLean > 100)
        totalLean = totalLean/1000;

    if (totalFat > 100)
        totalFat = totalFat/1000;

    return {
        totalLean,
        totalFat,
        totalWeight
    }
}

export default DXAIndexCalculator;
