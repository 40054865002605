import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {BACKEND_URL} from "../../constants";
import axios from "axios";
import moment from "moment/moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import HTTPServices from "../../Utils/HTTPServices";

const LoadGPSDataComponent = ({title, description, templateURL, uploadURL}) => {
    const [startDate, setStartDate] = useState(new Date());
    const [isMatch, setIsMatch] = useState(false);
    const [isAdditive, setIsAdditive] = useState(false);
    const [matches, setMatches] = useState([]);
    const [dayDiffToMatch, setDayDiffToMatch] = useState("0");
    const [selectedMatch, setSelectedMatch] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate();


    const isAblosuteURL = (url) => {
        return url.indexOf('http') === 0;
    }
    const donwloadTemplate = (url, filename) => {
        return () => {
            let config = {
                method: 'get',
                url: `${BACKEND_URL}${url}`,
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
            };

            axios.request(config).then((response) => {
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                }
                console.log(error);
            })
        }
    }

    const uploadExam = (event) => {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("date", moment(startDate).format('X'));
        formData.append("is_match", isMatch ? 1 : 0);
        formData.append("is_additive", isAdditive ? 1 : 0);
        if (isMatch) {
            formData.append("match_id", selectedMatch._id);
        } else {
            formData.append("day_diff_to_match", dayDiffToMatch);
        }

        let config = {
            method: 'post',
            url: `${BACKEND_URL}${uploadURL}`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        };

        axios.request(config).then((response) => {
            if (response.data.left_players && response.data.left_players.length > 0) {
                Swal.fire({
                    title: 'Datos cargados',
                    html: `<div>
                        <p>Los datos se cargaron correctamente</p>
                        <p>Los siguientes jugadores no se encuentran en la base de datos: ${response.data.left_players.join(', ')}</p>
                    </div>`,
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });
            } else {
                Swal.fire({
                    title: 'Datos cargados',
                    text: 'Los datos se cargaron correctamente',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
            }
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
            console.log(error);
        })
    }

    useEffect(() => {
        const getMatches = async () => {
            let client = new HTTPServices();
            try {
                let response = await client.getMatches()
                setMatches(response.data.matches);

            } catch (error) {

            }
        }

        getMatches();
    }, []);

    const selectMatch = () => {
        if (isMatch){

        }
    }

    return (
        <div>
            <div className="row d-flex justify-content-between">
                <span className="h2">{title}</span>
                <span>
                    {templateURL ?
                        isAblosuteURL(templateURL) ?
                            <a href={templateURL}>Descargar
                                Template</a> :
                            <a onClick={donwloadTemplate(templateURL, `Template-${title}.xlsx`)} href="#" >Descargar Template</a>
                        :
                        null
                    }
            </span>
            </div>
            <div className="row">
                <p className="color-gray">{description} </p>
            </div>
            <div className="row">
                <h3>Subir datos</h3>
            </div>
            <div className="row">
                <div className="col-12 mt-2">
                    <span>Selecciona el archivo con toda la información correspondiente</span>
                    <div>
                        <input type="file" name="file" onChange={(event) => setSelectedFile(event.target.files[0])}/>
                    </div>
                </div>
                <div className="col-12 mt-2">
                    <input type="checkbox" name="isAdditive" checked={isAdditive} onChange={(e) => {
                        setIsAdditive(e.target.checked)
                    }}/>
                    <span> Es aditivo</span>
                </div>
                <div className="col-12 mt-2">
                    <input type="checkbox" name="isMatch" checked={isMatch} onChange={(e) => {
                        setIsMatch(e.target.checked)
                        selectMatch()
                    }}/>
                    <span> Partido</span>
                </div>
                {isMatch &&
                    <div className="col-12 mt-2">
                        <span>Selecciona el partido al que corresponde el archivo</span>
                        <Select
                            placeholder="Seleccione encuentro"
                            options={matches}
                            getOptionLabel={(option) => `${option.team.company.name} V/S ${option.opponent.company.name} (${moment(option.date).format("DD/MM/YYYY")})`}
                            getOptionValue={(option) => option._id}
                            value={selectedMatch}
                            onChange={(option) => {
                                setSelectedMatch(option)
                            }}
                        />
                    </div>}
                {!isMatch &&
                    <div className="col-12 mt-2">
                        <span>Ingresa el dia con respecto al proximo o anterior partido </span>
                        <div>
                            <input
                                className="form-input"
                                type="number"
                                min={-6}
                                max={2}
                                name="dayDiffToMatch"
                                value={dayDiffToMatch}
                                onChange={(e)=> setDayDiffToMatch(e.target.value)}
                            />
                        </div>
                    </div>
                }
                <div className="col-12 mt-2">
                    <span>Selecciona la fecha de la toma de datos.</span>
                    <div>
                        <DatePicker
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                        />
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-end">
                    <button type="button" className="btn btn-primary" onClick={uploadExam}>Cargar</button>
                </div>
            </div>
        </div>
    );
}

export default LoadGPSDataComponent;