import UrineIndexCalculator from "../ExamIndexCalculators/UrineIndexCalculator";

const UrineStatusCalculator = (exam, player) => {
    let results = UrineIndexCalculator(exam);
    let status = [];

    if (results.specificGravity > 1.02) {
        status.push({
            value: 1,
            recommendation: `Se sugiere plan de hidratación`,
            name: "Deshidratación",
            dif: `${results.specificGravity} g/mL`,
            reference: "1.02 g/mL",
        });
    }

    return status;
}

export default UrineStatusCalculator;