import {BACKEND_URL} from "../constants";
import axios from "axios";

class HTTPServices {

    constructor() {
        this.client = axios.create({
            baseURL: BACKEND_URL,
            timeout: 30 * 1000,
            headers: {'Content-Type': 'application/json'}
        });
        if (localStorage.getItem('token')) {
            this.setToken(localStorage.getItem('token'));
        }
    }

    login(email, password) {
        return this.client.post('/login/', {
            email: email,
            password: password
        });
    }

    setToken(token) {
        this.client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    syncTeams() {
        return this.client.get('/teams/sync/');
    }

    syncMatches(team_id) {
        return this.client.get(`/matches/sync/${team_id ? `?team_id=${team_id}` : ''}`);
    }

    getTeams() {
        return this.client.get('/teams/');
    }

    getTeam(id) {
        return this.client.get(`/teams/${id}/`);
    }

    getLastResume() {
        return this.client.get('/check/resumes/last/');
    }

    getMatches(team_id) {
        return this.client.get(`/matches/${team_id ? `?team_id=${team_id}` : ''}`);
    }

    getMatch(id) {
        return this.client.get(`/matches/${id}/`);
    }

    getLastMatch() {
        return this.client.get('/matches/last/');
    }

    getNextMatch() {
        return this.client.get('/matches/next/');
    }

    updateMatch(match_id, resumes) {
        return this.client.put(`/matches/${match_id}/`, resumes);
    }

    // Urine

    getUrineTemplate() {
        return this.client.get('/check/urine/template/');
    }

    uploadUrine(data) {
        return this.client.post('/check/urine/', data);
    }

    // Sweat

    getSweatTemplate() {
        return this.client.get('/check/sweat/template/');
    }

    uploadSweat(data) {
        return this.client.post('/check/sweat/', data);
    }

    // Clinical

    uploadClinical(data) {
        return this.client.post('/check/clinical/', data);
    }

    // GPS data

    getGPSTemplate() {
        return this.client.get('/check/gps/template/');
    }

    uploadGPS(data) {
        return this.client.post('/check/gps/', data);
    }

    getGPS(is_match) {
        if (is_match !== undefined && is_match !== null) {
            return this.client.get(`/check/gps/${is_match ? '?is_match=1' : '?is_match=0'}`);
        } else {
            return this.client.get('/check/gps/');
        }
    }

    // Skinfold

    getSkinfoldTemplate() {
        return this.client.get('/check/skinfold/template/');
    }

    uploadSkinfold(data, player_id, date) {
        return this.client.post('/check/skinfold/', {'data': data, 'player': player_id, 'date': date});
    }

    getSkinfold(player_id) {
        return this.client.get(`/check/skinfold/${player_id}/`);

    }

    // Create resume

    createResume() {
        return this.client.get('/check/create_resume/');
    }

    getLastResume() {
        return this.client.get('/check/resumes/last/');
    }

    // Exams
    createExam(data, examType) {
        return this.client.post('/exams/', {data, exam_type: examType});
    }

    getExams({startDate, endDate, player, examType}) {
        let url = '/exams/';
        if (startDate) {
            url += `?start_date=${startDate}`;
        }
        if (endDate) {
            url += `&end_date=${endDate}`;
        }
        if (player) {
            url += `&player=${player}`;
        }
        if (examType) {
            url += `&exam_type=${examType}`;
        }
        return this.client.get(url);
    }
}

export default HTTPServices;