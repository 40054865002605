import {Link} from "react-router-dom";
import "./TemplateStyle.styles.css";

const SLABTemplate = (props) => {
    const user = JSON.parse(localStorage.getItem("user"));

    return (
        <div>
            <div className="navbar navbar-expand-md fixed-top navbar-dark bg-main" >
                <div className="container">
                    <a className="navbar-brand d-flex align-items-center" href="/home">
                        <img className="" src="/images/slab-logo-dark.jpeg" alt="" height="80"/>
                        <span className="text-white h3 navbar-text ml-2 slab-font">SLAB</span>
                    </a>
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav ml-auto">

                            <li className={`nav-item item  ${props.title === "Dashboard" ? "active" : ""}`}>
                                <Link className="nav-link slab-font" to="/home">Dashboard</Link>
                            </li>

                            <li className={`nav-item item  ${props.title === "Estado" ? "active" : ""}`}>
                                <Link className="nav-link slab-font" to="/health">Estado de Salud</Link>
                            </li>

                            <li className={`nav-item item  ${props.title === "Reporte" ? "active" : ""}`}>
                                <Link className="nav-link slab-font" to="/report">Reportes</Link>
                            </li>

                            {["nutrition", "dt", "admin"].includes(user.role.name) &&
                                <li className={`nav-item item  ${props.title === "Nutrición" ? "active" : ""}`}>
                                    <Link className="nav-link slab-font" to="/nutrition">
                                        Carga de Datos {user.role.name === "admin" ? "Nutrición" : ""}
                                    </Link>
                                </li>}
                            {["medicine", "dt", "admin"].includes(user.role.name) &&
                                <li className={`nav-item item  ${props.title === "Medicina y Rehabilitación" ? "active" : ""}`}>
                                    <Link className="nav-link slab-font" to="/med-rehab">
                                        Carga de Datos {user.role.name === "admin" ? "Medicina" : ""}
                                    </Link>
                                </li>}
                            {["data", "dt", "admin"].includes(user.role.name) &&
                                <li className={`nav-item item  ${props.title === "Data y Rendimiento" ? "active" : ""}`}>
                                    <Link className="nav-link slab-font" to="/performance">
                                        Carga de Datos {user.role.name === "admin" ? "GPS" : ""}
                                    </Link>
                                </li>}
                            {user.role.name === "admin" &&
                                <li className={`nav-item item  ${props.title === "exam_upload" ? "active" : ""}`}>
                                    <Link className="nav-link slab-font" to="/exams/upload">Carga de Exámenes</Link>
                                </li>
                            }
                            {/*<li className={`nav-item item ${props.title === "Tactica" ? "active" : ""}`}>*/}
                            {/*    <Link className="nav-link slab-font" to="/tactics">Táctico</Link>*/}
                            {/*</li>*/}
                            <li className={`nav-item item`}>
                                <Link className="nav-link slab-font" to="/">Cerrar sesión</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container navbar-margin">
                {props.children}
                <div className="end-margin"/>
            </div>
        </div>
    );
}

export default SLABTemplate;