import RadiometerExamComponent from "./RadiometerExam.component";
import DXAExamComponent from "./DXAExam.component";
import BiomechanicComponent from "./Biomechanic.component";
import IsokineticExamComponent from "./IsokineticExam.component";
import UrineExamComponent from "./UrineExam.component";
import SweatExamComponent from "./SweatExam.component";
import LactatoExamComponent from "./LactatoExam.component";

const getExamComponent = (exam, user, id) => {
        if (exam.type.code === 'radiometer-venosa') {
            return <RadiometerExamComponent key={`exam-${id}`} exam={exam} />
        } else if (exam.type.code === 'dexa') {
            return <DXAExamComponent key={`exam-${id}`}  exam={exam} />
        } else if (exam.type.code === 'biomecanica') {
            return <BiomechanicComponent key={`exam-${id}`}  exam={exam} />
        } else if (exam.type.code === 'isocinetica') {
            return <IsokineticExamComponent key={`exam-${id}`}  exam={exam} user={user} />
        } else if (exam.type.code === 'radiometer') {
            return <RadiometerExamComponent key={`exam-${id}`}  exam={exam} />
        } else if (exam.type.code === 'urine') {
            return <UrineExamComponent key={`exam-${id}`}  exam={exam} />
        } else if (exam.type.code === 'sweat') {
            return <SweatExamComponent key={`exam-${id}`}  exam={exam} />
        } else if (exam.type.code === 'lactato') {
            return <LactatoExamComponent key={`exam-${id}`}  exam={exam} />
        }
    }

export default getExamComponent;