const UrineExamComponent = ({exam}) => {

    let specificGravity = exam.results[0].values[0].value;

    return (
    <div className="row">
        <div className="col-md-4 col-xs-12">
            <div className="card mt-3">
                <div className="card-header">
                    <h5 className="card-title">Gravedad especifica</h5>
                </div>
                <div className="card-body">
                    <h5 className="card-title">{specificGravity.toFixed(3)}</h5>
                </div>
            </div>
        </div>
    </div>);

}

export default UrineExamComponent;