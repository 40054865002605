import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {useEffect} from "react";
import mixpanel from 'mixpanel-browser';
import LoginPage from '../Pages/LoginPage/LoginPage.component';
import PatientsPage from "../Pages/PatientsPage/PatientsPage.component";
import PatientStatsPage from "../Pages/PatientStatspage/PatientStatspage.component";
import PatientStatsResume from "../Pages/PatientStatsResume/PatientStatsResume.component";
import PerformancePage from "../Pages/PerformancePage/PerformancePage.component";
import ProtectedRoute from "./ProtectedRoute.component";
import PatientResumeComponent from "../Pages/PatientResume/PatientResume.component";
import NutritionPage from "../Pages/NutritionPage/NutritionPage.component";
import ExamUploadComponent from "../Pages/ExamUpload/ExamUpload.component";
import MedicinePage from "../Pages/MedicinePage/MedicinePage.component";
import DashboardPage from "../Pages/Dashboard/DashboardPage.component";
import MatchResumePage from "../Pages/MatchResume/MatchResumePage.component";
import ReportPage from "../Pages/ReportPage/ReportPage.component";
import StatsViewComponent from "../Pages/PatientStatspage/StatsView.component";
const AppRouter = () => {

    useEffect(() => {
        document.title = 'SLAB';
        mixpanel.init('86f39fbbd9ac8d9105f21d1514d67904', {debug: true, track_pageview: true, persistence: 'localStorage'});
    }, []);

    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<LoginPage />} />

                {/* Dashboard */}
                <Route path="/home" element={
                    <ProtectedRoute>
                        <DashboardPage />
                    </ProtectedRoute>
                } />

                {/* Match data */}
                <Route path="/match/:matchId/" element={<MatchResumePage />} />

                {/* Reports */}
                <Route path="/report" element={<ReportPage />} />

                {/* Health data */}
                <Route path="/health" element={<PatientsPage />} />
                <Route path="/health/player/:playerId/resume/" element={<PatientResumeComponent />} />
                <Route path="/health/visit/player/:playerId/detail" element={<PatientStatsPage />} />
                <Route path="/health/player/1/detail" element={<PatientStatsResume />} />

                {/*Carga de datos*/}
                <Route path="/exams/upload" element={<ExamUploadComponent />} />
                <Route path="/nutrition" element={<NutritionPage />} />
                <Route path="/med-rehab" element={
                    <ProtectedRoute>
                        <MedicinePage />
                    </ProtectedRoute>
                } />
                <Route path="/performance" element={
                    <ProtectedRoute>
                        <PerformancePage />
                    </ProtectedRoute>
                } />

            </Routes>
        </Router>
    );
}

export default AppRouter;