import BiomechanicIndexCalculator from "../ExamIndexCalculators/BiomechanicIndexCalculator";

const BiomechanicStatusCalculator = (exam, player) => {
    let results = BiomechanicIndexCalculator(exam, player);
    let status = []

    //Plano sagital

    let [izq, der] = results.asimetriaRodillaSagEXT

    if (Math.abs(izq) < 0 || Math.abs(der) < 0)
        status.push({
            value: 1,
            recommendation: "Se recomienda trabajar grupo muscular flexor de rodilla (flexibilidad) para facilitar rango durante la fase de vuelo.",
            name: "Extención de rodilla limitada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "0",
            meaning: "Rango de movilidad de la articulación de la rodilla"
        });

    if (Math.abs(izq) > 2 || Math.abs(der) > 2)
        status.push({
            value: 1,
            recommendation: "Se recomienda trabajar grupo muscular flexor de rodilla (contracción excéntrica) para facilitar rango durante la fase de vuelo.",
            name: "Extención de rodilla aumentada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "2",
            meaning: "Rango de movilidad de la articulación de la rodilla"
        });

    [izq, der] = results.asimetriaRodillaSagFLEX

    if (Math.abs(izq) < 83 || Math.abs(der) < 83)
        status.push({
            value: 1,
            recommendation: "Se recomienda trabajar grupo muscular extensor de rodilla  (flexibilidad) para facilitar rango durante la fase de vuelo.",
            name: "Flexión de rodilla limitada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "83",
            meaning: "Rango de movilidad de la articulación de la rodilla"
        });

    if (Math.abs(izq) > 104 || Math.abs(der) > 104)
        status.push({
            value: 1,
            recommendation: "Se recomienda trabajar grupo muscular extensor de rodilla  (contracción excéntrica) para facilitar rango durante la fase de vuelo.",
            name: "Flexión de rodilla aumentada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "104",
            meaning: "Rango de movilidad de la articulación de la rodilla"
        });

    [izq, der] = results.asimetriaCaderaSagEXT

    if (Math.abs(izq) < 0 || Math.abs(der) < 0)
        status.push({
            value: 1,
            recommendation: "Se recomienda trabajar grupo muscular flexor de cadera (flexibilidad) para facilitar rango durante la fase de vuelo.",
            name: "Extención de cadera limitada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "0",
            meaning: "Rango de movilidad de la articulación de la cadera"
        });

    if (Math.abs(izq) > 8 || Math.abs(der) > 8)
        status.push({
            value: 1,
            recommendation: "Se recomienda trabajar grupo muscular flexor de cadera (contracción excéntrica) para facilitar rango durante la fase de vuelo.",
            name: "Extención de cadera aumentada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "8",
            meaning: "Rango de movilidad de la articulación de la cadera"
        });

    [izq, der] = results.asimetriaCaderaSagFLEX

    if (Math.abs(izq) < 38 || Math.abs(der) < 38)
        status.push({
            value: 1,
            recommendation: "Se recomienda trabajar grupo muscular extensor de cadera  (flexibilidad) para facilitar rango durante la fase de vuelo.",
            name: "Flexión de cadera limitada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "38",
            meaning: "Rango de movilidad de la articulación de la cadera"
        });

    if (Math.abs(izq) > 50 || Math.abs(der) > 50)
        status.push({
            value: 1,
            recommendation: "Se recomienda trabajar grupo muscular extensor de cadera  (contracción excéntrica) para facilitar rango durante la fase de vuelo.",
            name: "Flexión de cadera aumentada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "50",
            meaning: "Rango de movilidad de la articulación de la cadera"
        });

    [izq, der] = results.asimetriaTobilloSagDF

    if (Math.abs(izq) < 23 || Math.abs(der) < 23)
        status.push({
            value: 1,
            recommendation: "Se recomienda trabajar grupo muscular anterior de pierna (flexibilidad) para facilitar rango durante la fase de vuelo.",
            name: "Dorsoflexión de tobillo limitada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "23",
            meaning: "Rango de movilidad de la articulación del tobillo"
        });

    if (Math.abs(izq) > 29 || Math.abs(der) > 29)
        status.push({
            value: 1,
            recommendation: "Se recomienda trabajar grupo muscular anterior de pierna (contracción excéntrica) para facilitar rango durante la fase de vuelo.",
            name: "Dorsoflexión de tobillo aumentada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "29",
            meaning: "Rango de movilidad de la articulación del tobillo"
        });

    [izq, der] = results.asimetriaTobilloSagPF

    if (Math.abs(izq) < 11 || Math.abs(der) < 11)
        status.push({
            value: 1,
            recommendation: "Se recomienda trabajar grupo muscular posterior de pierna  (flexibilidad) para facilitar rango durante la fase de vuelo.",
            name: "Flexión plantar de tobillo limitada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "11",
            meaning: "Rango de movilidad de la articulación del tobillo"
        });

    if (Math.abs(izq) > 22 || Math.abs(der) > 22)
        status.push({
            value: 1,
            recommendation: "Se recomienda trabajar grupo muscular posterior de pierna  (contracción excéntrica) para facilitar rango durante la fase de vuelo.",
            name: "Flexión plantar de tobillo aumentada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "22",
            meaning: "Rango de movilidad de la articulación del tobillo"
        });

    //Plano frontal

    [izq, der] = results.asimetriaCadFrontalABD

    if (Math.abs(izq) < 9 || Math.abs(der) < 9)
        status.push({
            value: 0.75,
            recommendation: "Se recomienda trabajar grupo muscular aductor de cadera (flexibilidad) para facilitar rango durante la fase de vuelo.",
            name: "Abducción de cadera limitada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "9",
            meaning: "Rango de movilidad de la articulación de la cadera"
        });

    if (Math.abs(izq) > 15 || Math.abs(der) > 15)
        status.push({
            value: 0.75,
            recommendation: "Se recomienda trabajar grupo muscular aductor de cadera (contracción excéntrica) para facilitar rango durante la fase de vuelo.",
            name: "Abducción de cadera aumentada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "15",
            meaning: "Rango de movilidad de la articulación de la cadera"
        });

    [izq, der] = results.asimetriaCadFrontalADD

    if (Math.abs(izq) < -5 || Math.abs(der) < -5)
        status.push({
            value: 0.75,
            recommendation: "Se recomienda trabajar grupo muscular abductor de cadera (flexibilidad) para facilitar rango durante la fase de vuelo.",
            name: "Aducción de cadera limitada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "-5",
            meaning: "Rango de movilidad de la articulación de la cadera"
        });

    if (Math.abs(izq) > 1 || Math.abs(der) > 1)
        status.push({
            value: 0.75,
            recommendation: "Se recomienda trabajar grupo muscular abductor de cadera (contracción excéntrica) para facilitar rango durante la fase de vuelo.",
            name: "Aducción de cadera aumentada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "1",
            meaning: "Rango de movilidad de la articulación de la cadera"
        });

    [izq, der] = results.asimetriaRodillaFrontalVARO

    // if (Math.abs(izq) < -5 || Math.abs(der) < -5)
    //     status.push({
    //         value: 0.75,
    //         recommendation: "Se recomienda trabajar grupo muscular aductor de rodilla (flexibilidad) para facilitar rango durante la fase de vuelo.",
    //         name: "Varo de rodilla limitada",
    //         dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
    //         reference: "-5",
    //         meaning: "Rango de movilidad de la articulación de la rodilla"
    //     });

    if (Math.abs(izq) > 1 || Math.abs(der) > 1)
        status.push({
            value: 0.75,
            recommendation: "Se recomienda trabajar estabilizadores de cadera y ver potencial estrés durante la fase de contacto.",
            name: "Varo de rodilla aumentada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "1",
            meaning: "Rango de movilidad de la articulación de la rodilla"
        });

    [izq, der] = results.asimetriaRodillaFrontalVALGO

    // if (Math.abs(izq) < 3 || Math.abs(der) < 3)
    //     status.push({
    //         value: 0.75,
    //         recommendation: "Se recomienda trabajar estabilizadores de cadera y ver potencial estrés durante la fase de contacto.",
    //         name: "Valgo de rodilla limitada",
    //         dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
    //         reference: "3",
    //         meaning: "Rango de movilidad de la articulación de la rodilla"
    //     });

    if (Math.abs(izq) > 8 || Math.abs(der) > 8)
        status.push({
            value: 0.75,
            recommendation: "Se recomienda trabajar estabilizadores de cadera y ver potencial estrés durante la fase de contacto.",
            name: "Valgo de rodilla aumentada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "8",
            meaning: "Rango de movilidad de la articulación de la rodilla"
        });

    // Plano transversal

    [izq, der] = results.asimetriaRodillaTraversoRMED

    // if (izq < -15 || der < -15)
        // status.push({
        //     value: 3,
        //     recommendation: "Se recomienda trabajar grupo muscular rotador interno de cadera (flexibilidad) para facilitar rango durante la fase de vuelo.",
        //     name: "Rotación medial de rodilla limitada",
        //     dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
        //     reference: "-9",
        //     meaning: "Rango de movilidad de la articulación de la rodilla"
        // });

    if (izq> -9 || der > -9)
        status.push({
            value: 3,
            recommendation: "Se recomienda trabajar estabilizadores de cadera, contracción de rotadores externos  y ver potencial estrés durante la fase de contacto.",
            name: "Rotación medial de rodilla aumentada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "-9",
            meaning: "Rango de movilidad de la articulación de la rodilla"
        });

    [izq, der] = results.asimetriaRodillaTraversoRLAT

    // if (Math.abs(izq) < 15 || Math.abs(der) < 15)
    //     status.push({
    //         value: 3,
    //         recommendation: "Se recomienda trabajar grupo muscular rotador externo de cadera (flexibilidad) para facilitar rango durante la fase de vuelo.",
    //         name: "Rotación lateral de rodilla limitada",
    //         dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
    //         reference: "15",
    //         meaning: "Rango de movilidad de la articulación de la rodilla"
    //     });

    if (Math.abs(izq) > 21 || Math.abs(der) > 21)
        status.push({
            value: 3,
            recommendation: "Se recomienda trabajar estabilizadores de cadera, contracción de rotadores internos  y ver potencial estrés durante la fase de contacto.",
            name: "Rotación lateral de rodilla aumentada",
            dif: `Izquierda: ${Math.abs(izq)} - Derecha: ${Math.abs(der)}`,
            reference: "21",
            meaning: "Rango de movilidad de la articulación de la rodilla"
        });

    return status
}

export default BiomechanicStatusCalculator;