
import Swal from 'sweetalert2'

import './StatsView.styles.css'
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import moment from "moment";
import examHandler from "../../ExamHandler/ExamHandler";
import axios from "axios";
import {BACKEND_URL} from "../../constants";

const PatientStatsPage = (props) => {

    const [check, setCheck] = useState();
    const [canEdit, setCanEdit] = useState(false);
    const [examNotes, setExamNotes] = useState({});
    const [sectionRecommendations, setSectionRecommendations] = useState({});
    const location  = useLocation()
    const user = JSON.parse(localStorage.getItem('user'));


    useEffect(() => {
        let check = location.state;
        check.sections = check.sections.sort((s) => s.order);
        setCheck(check);

        let notes = {};
        check.sections.map((section) => {
            notes[section.name] = section.exams.map((exam) => {
                return exam.notes || "";
            });
        });
        setExamNotes(notes);

        let sRecommendations = {};
        check.sections.map((section) => {
            sRecommendations[section.name] = section.recommendations || "";
        });
        setSectionRecommendations(sRecommendations);

        if (user.role && user.role.name === 'admin') {
            setCanEdit(true);
        }
    }, []);

    useEffect(() => {
        if (check) {
            document.title = check.name;
        }
    }, [check]);

    const  updateExamNotes = (sectionName, examIndex, reference) => {
        let config = {
            method: 'put',
            url: `${BACKEND_URL}/check/${reference.id}/notes/`,
            data: {
                note: examNotes[sectionName][examIndex],
                sectionName,
                examIndex
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        };

        axios.request(config).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Notas actualizadas',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch((err) => {
            console.log(err);
            Swal.fire({
                icon: 'error',
                title: 'Error al actualizar notas',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }

    const updateSectionRecommendations = (sectionName, reference) => {
        let config = {
            method: 'put',
            url: `${BACKEND_URL}/check/${reference.id}/recommendations/`,
            data: {
                recommendation: sectionRecommendations[sectionName],
                sectionName: sectionName
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        };

        axios.request(config).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Recomendaciones actualizadas',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch((err) => {
            Swal.fire({
                icon: 'error',
                title: 'Error al actualizar recomendaciones',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }


    return !check ?
        <div className="container mb-5">
            <div className="row d-flex justify-content-end mt-3">
                <img src="/images/slab-logo.jpeg" alt="SLAB logo" height="150"/>
            </div>
            <h1 className="report-title">Cargando</h1>
        </div>
        : (
        <div className="container mb-5">
            <div className="row d-flex justify-content-end mt-3">
                <img src="/images/slab-logo.jpeg" alt="SLAB logo" height="150"/>
            </div>
            <h1 className="report-title">Evaluación Fisiológica</h1>
            <hr/>
            <div className="patient-data section">
                <h2 className="section-title">Antecedentes Generales</h2>
                <div className="separation my-4"/>
                <div className="row">
                    <div className="col-md-4 col-xs-12">
                        <div className="data-row">
                            <span className="data-key-label">Nombre</span>
                            <span className="data-value-label">{check.user.name}</span>
                        </div>
                        {/*<div className="data-row">*/}
                        {/*    <span className="data-key-label">Fecha Evaluación</span>*/}
                        {/*    <span className="data-value-label">{moment(check.visit.created_at).format("DD/MM/YYYY")}</span>*/}
                        {/*</div>*/}
                        <div className="data-row">
                            <span className="data-key-label">Deporte</span>
                            <span className="data-value-label">FUTBOL, {check.user.position}</span>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                        <div className="data-row">
                            <span className="data-key-label">Fecha Nacimiento</span>
                            <span className="data-value-label">{moment(check.user.birthdate).format("DD/MM/YYYY")}</span>
                        </div>
                        <div className="data-row">
                            <span className="data-key-label">IMC (kg/m<sup>2</sup>)</span>
                            <span className="data-value-label">{(check.user.currentData.weight/(check.user.currentData.height/100)**2).toFixed(0)}</span>
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                        <div className="data-row">
                            <span className="data-key-label">Peso (Kg.)</span>
                            <span className="data-value-label">{check.user.currentData.weight}</span>
                        </div>
                        <div className="data-row">
                            <span className="data-key-label">Talla (cm)</span>
                            <span className="data-value-label">{check.user.currentData.height}</span>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div>

            {
                check.sections.sort((a, b) => a.index - b.index).map((section, sIndex) =>
                    <div className="v02-data section" key={`sec-${sIndex}`}>
                        <h2 className="section-title">{section.name}</h2>
                        <div className="separation my-4" key={`sec-sep-${sIndex}`}/>
                        {
                            section.exams.map((exam, eIndex) =>
                                <div key={`sec-exams-${sIndex}`}>
                                    {examHandler(exam, check.user, `${sIndex}-${eIndex}`)}
                                    {/*{exam.type.code !== "medical_comment" ?*/}
                                    {/*    <div className="row comments-sections" key={`sec-comm-${sIndex}-ex-${eIndex}`}>*/}

                                    {/*        <h2 className="comments-sections-title col-12">Notas</h2>*/}
                                    {/*        {canEdit ?*/}
                                    {/*            <div className="comments-sections-content">*/}
                                    {/*                <p>{examNotes[section.name][eIndex] || "Sin recomendaciones"}</p>*/}
                                    {/*            </div> :*/}
                                    {/*            <>*/}
                                    {/*                <div className="comments-sections-content">*/}
                                    {/*    <textarea className="form-control w-100"*/}
                                    {/*              rows={5}*/}
                                    {/*              onChange={(e) => {*/}
                                    {/*                  let newNotes = {...examNotes};*/}
                                    {/*                  newNotes[section.name][eIndex] = e.target.value;*/}
                                    {/*                  setExamNotes(newNotes);*/}
                                    {/*              }*/}

                                    {/*              }*/}
                                    {/*    >*/}
                                    {/*        {examNotes[section.name][eIndex]}*/}
                                    {/*    </textarea>*/}
                                    {/*                </div>*/}

                                    {/*                <div className="col-12 d-flex justify-content-end mt-3">*/}
                                    {/*                    <button*/}
                                    {/*                        className="btn btn-primary"*/}
                                    {/*                        onClick={() => updateExamNotes(section.name, eIndex, exam.reference)}*/}
                                    {/*                    >Guardar Notas</button>*/}
                                    {/*                </div>*/}
                                    {/*            </>*/}
                                    {/*        }*/}
                                    {/*    </div>: <></>}*/}

                                </div>)
                        }
                        <div className="row comments-sections">
                            <h2 className="comments-sections-title col-12">Comentarios</h2>
                            {canEdit ?
                                <>
                                    <div className="comments-sections-content">
                                        <textarea
                                            rows={5}
                                            key={`sec-rec-${sIndex}`}
                                            className="form-control w-100"
                                            onChange={(e) => {
                                                let newRecommendations = {...sectionRecommendations};
                                                newRecommendations[section.name] = e.target.value;
                                                setSectionRecommendations(newRecommendations);
                                            }
                                            }
                                            value={sectionRecommendations[section.name].replace(/<br\s*\/?>/mg,"\n")}
                                        >
                                        </textarea>
                                    </div>

                                    {/*<div className="col-12 d-flex justify-content-end mt-3">*/}
                                    {/*    <button className="btn btn-primary" onClick={() => updateSectionRecommendations(section.name)}>Guardar Recomendación</button>*/}
                                    {/*</div>*/}
                                    </> :
                                <div className="comments-sections-content">
                                    <p>{section.recommendations || "Sin recomendaciones"}</p>
                                </div>
                            }

                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default PatientStatsPage;