const LactatoIndexCalculator = (exam) => {
    let lactatoValues = exam.results.map((result) => {
        return result.values[0].value;
    });

    return {
        lactatoValues: lactatoValues
    }
}

export default LactatoIndexCalculator;