import {ResponsiveBar} from "@nivo/bar";
import {ResponsiveLine} from "@nivo/line";

const LactatoExamComponent = ({exam, user}) => {

    return (
        <div className="row">
            <div className="col" style={{height: "350px"}}>
                <ResponsiveLine
                    minValue={0}
                    maxValue={5}
                    clamp={true}
                    markers={[
                        {
                            axis: 'y',
                            value: 4,
                            lineStyle: {
                                stroke: 'rgba(255,0,0,0.35)',
                                strokeWidth: 5
                            },
                            legend: 'Máximo',
                            legendOrientation: 'vertical',
                        }
                    ]}
                    data={[{
                        data: exam.results.map((result, index) => {
                            return{
                                "x": result.name,
                                "y": result.values[0].value
                            }
                        }),
                        "color": "#749BBE",
                        id: "Lactato"
                    }]}
                    margin={{top: 50, right: 50, bottom: 50, left: 50}}
                    padding={0.3}
                    labelTextColor={"#FFFFFF"}
                    valueScale={{type: 'linear'}}
                    yScale={{type: 'linear', min: 0, max: 5}}

                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Velocidad',
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Lactato (mmol/L)',
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                />
            </div>
        </div>
    )
}

export default LactatoExamComponent;