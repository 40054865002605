const TeamStatus = ({resumes}) => {

    let mean = resumes.map(resume =>
      resume.indexes.map(index => index.score).reduce((a, b) => a + b, 0)/resume.indexes.length
    ).reduce((a, b) => a + b, 0)/resumes.length

    let result = {color: 'green', description: "Riesgo bajo de lesión"};
    if (mean >= 3) {
        result =  {color: 'red', description: "Riesgo de lesión alto"};
    }  else if (mean > 2) {
        result = {color: 'orange', description: "Riesgo de lesión alto"};
    } else if (mean >= 1) {
        result =  {color: 'yellow', description: "Riesgo moderado de lesión"};
    } else {
        result =  {color: 'green', description: "Riesgo bajo de lesión"};
    }

    // Card with the team status chart
    return (<div className="card">
        <div className="card-header">
            <h3>Riesgo general del equipo</h3>
        </div>
        <div className="card-body">
            <div className="row justify-content-center">
                <div className={`${result.color}`}  style={{width: "100px", height: "100px", backgroundColor: result.color, borderRadius: "50%"}}>

                </div>
                <div className="row">
                    <h1 className="text-center h3">{result.description}</h1>
                </div>
            </div>
        </div>
    </div>
    );
}

export default TeamStatus;