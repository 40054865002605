import SLABTemplate from "../../Template/SLABTemplate.component";
import Tabs from "../../Components/Tabs/Tabs";
import GPSChartComponent from "../../Components/GPS_Charts/GPSChart.component";
import SharpLoadComponent from "../../Components/SharpLoad/SharpLoad.component";
import SharpLoadTable from "../../Components/SharpLoad/SharpLoad.component";
import {useEffect, useState} from "react";
import HTTPServices from "../../Utils/HTTPServices";
import moment from "moment";
import LoadAvailabilityReport from "../../Components/LoadAvailabilityReport/LoadAvailabilityReport.component";
import LoadingPlaceHolder from "../../Components/LoadingPlaceHolder/LoadingPlaceHolder";
import TrainningChart from "../../Components/GPS_Charts/TrainningChart";
import RadarChart from "../../Components/RadarChart/RadarChart.component";
import GPSComparative from "../../Components/GPSComparative/GPSComparative.component";

const ReportPage = (props) => {

    let user = JSON.parse(localStorage.getItem("user"));
    let team = JSON.parse(sessionStorage.getItem('team'));
    const [GPSData, setGPSData] = useState([]);
    const [trainGPSData, settrainGPSData] = useState([]);
    const [matchData, setMatchData] = useState([]);

    const [hideAvailability, setHideAvailability] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let client = new HTTPServices();

        let promises = Promise.all([
            client.getGPS(true),
            client.getGPS(false),
            client.getMatches(team.id),
        ]).then(([matchGPSResponse, trainningResponse, matchDataResponse]) => {
            let matchGPSData = matchGPSResponse.data.data
            let trainingData = trainningResponse.data.data

            matchGPSData = processData(matchGPSData)
            trainingData = processData(trainingData)

            matchGPSData.sort((a, b) => {
                return a.date.toDate() > b.date.toDate() ? -1 : 1
            })
            trainingData.sort((a, b) => {
                return a.date.toDate() > b.date.toDate() ? -1 : 1
            })

            let matchData = matchDataResponse.data.matches;
            matchData.sort((a, b) => {
                return moment(a).toDate() > moment(b).toDate() ? -1 : 1
            })

            setMatchData(matchData);



            setGPSData(matchGPSData);
            settrainGPSData(trainingData);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    const processData = (datum) => {
        return datum.map((item) => {
            item.data = item.data.map((player_data) => {
                let player = team.players.find((player) => player.id === player_data.player)
                return {player: player, data: player_data.data}
            })
            return {
                ...item,
                date: moment(item.date)
            }
        })
    }

    const pages = [
        {
            id: "gps-analysis",
            name: "Analisis datos GPS",
            component: <GPSChartComponent GPSdata={GPSData}/>
        },{
            id: "gps-training",
            name: "Analisis de Carga en Entrenamiento",
            component: <TrainningChart trainingGPSData={trainGPSData} matchGPSData={GPSData} matchData={matchData}/>
        }, {
            id: "sharp-training-load",
            name: "Carga diaria de Entrenamiento",
            component: <GPSComparative trainingGPSData={trainGPSData} matchGPSData={GPSData} />
        },  {
            id: "gps-sharp-table",
            name: "Analisis de Carga Aguda",
            component: <SharpLoadTable GPSdata={GPSData}/>
        }
    ]

    if (!hideAvailability) {
        pages.push({
            id: "availability",
            name: "Disponibilidad",
            component: <LoadAvailabilityReport/>
        })
    }




    return (
        <SLABTemplate title="Reporte">
            <div className="row justify-content-center">
                <span className="font-weight-bold h1">Reporte</span>
                <p className="text-center">Aquí podras encontrar diferentes reportes de las multiples areas, estas se actualizarán automaticamente mediante los datos que se suban, tanto desde el area nutricional como el area de GPS o médica. </p>
                <hr/>
            </div>
            <div className="row">
                <div className="col-2">
                    <img className="w-100" src={user.company.photo} alt="team-logo"/>
                </div>
                <div className="col-10">
                    <span className="h2 color-main">{user.company.name}</span>
                </div>
            </div>
            <LoadingPlaceHolder loading={loading}>
                <div className="row mt-5">
                    <Tabs
                        pages={pages}
                    />
                </div>
            </LoadingPlaceHolder>
        </SLABTemplate>
    );
}

export default ReportPage;
