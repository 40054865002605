import {useParams} from "react-router-dom";
import SLABTemplate from "../../Template/SLABTemplate.component";

const PatientStatsResume = (props) => {

    const params = useParams();
    let message = <div>
        <p>Grave desgarro en los isquiotibiales. Según la resonancia magnética, es un desgarro de grado 3, lo que significa que es una rotura completa de las fibras musculares de los isquiotibiales. Pero no te preocupes, vamos a conseguir que vuelvas al campo más fuerte que nunca. Aquí tienes el diagnóstico y el plan de entrenamiento:
            <br/>
            <br/>
            DIAGNÓSTICO:
            <br/>
            Desgarro de grado 3 en los isquiotibiales<br/>
            Daño muscular importante<br/>
            Posibilidad de volver a lesionarse si no se trata y rehabilita adecuadamente<br/>
            <br/>
            PLAN DE TRATAMIENTO:<br/>
            <br/>
            TRATAMIENTO INMEDIATO:<br/>
            Aplicar hielo en la zona lesionada para reducir la hinchazón y la inflamación.<br/>
            Descanse y eleve la pierna para minimizar la tensión en el músculo lesionado.<br/>
            Prescribir medicación antiinflamatoria para controlar el dolor y reducir la hinchazón.<br/>
            <br/>
            FISIOTERAPIA:<br/>
            Comience las sesiones de fisioterapia durante la primera semana para mantener la amplitud de movimiento de la articulación y evitar la atrofia muscular.<br/>
            Céntrese en ejercicios suaves de estiramiento y fortalecimiento para promover la curación y prevenir la formación de tejido cicatricial.<br/>
            <br/>
            REHABILITACIÓN:<br/>
            Progresar de ejercicios pasivos a ejercicios activos de amplitud de movimiento.<br/>
            Incorpore ejercicios de fortalecimiento isométricos y excéntricos para los músculos isquiotibiales.<br/>
            Introduzca gradualmente ejercicios funcionales, como sentadillas y estocadas, para recuperar la fuerza y la estabilidad.<br/>
            <br/>
            RECUPERACIÓN ESPECÍFICA PARA EL DEPORTE:<br/>
            Trabaje con un fisioterapeuta especializado en deportes para simular movimientos de fútbol y ejercicios de agilidad.<br/>
            Reintroduzca gradualmente ejercicios específicos de fútbol y trabajo con balón para evaluar si está preparado para volver a jugar.<br/>
            <br/>
            SEGUIMIENTO Y AJUSTES:<br/>
            Evalúe periódicamente los progresos mediante evaluaciones clínicas e imágenes.<br/>
            Ajuste el plan de rehabilitación en función de su respuesta al tratamiento.<br/>
            <br/>
            NUTRICIÓN E HIDRATACIÓN:<br/>
            Mantenga una dieta equilibrada rica en proteínas, vitaminas y minerales para favorecer la reparación y el crecimiento de los tejidos.<br/>
            Manténgase bien hidratado para contribuir al proceso de recuperación.<br/>
            <br/>
            APOYO PSICOLÓGICO:<br/>
            Considere la posibilidad de consultar a un psicólogo deportivo para que le ayude a gestionar el impacto psicológico de la lesión.</p>
    </div>;
    if (Object.keys(params).includes("treatment")){
        if (params.treatment === "integral"){

        } else if (params.treatment === "muscular"){

        } else if (params.treatment === "isquiotibiales"){

        }
    }

    return (
        <SLABTemplate title="Estado">
            <h1>Resumen Diagnostico: Rafael Varane</h1>
            <div>
                {message}
            </div>
        </SLABTemplate>
    );
}

export default PatientStatsResume;