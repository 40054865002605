import {TailSpin} from "react-loader-spinner";
import React from "react";

const LoadingPlaceHolder = (props) => {
    return props.loading ? (
        <div className="row justify-content-center">
            <TailSpin
                visible={true}
                height="80"
                width="80"
                color="#011634"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>) : props.children;
}

export default LoadingPlaceHolder;