import SLABTemplate from "../../Template/SLABTemplate.component";
import React, {useEffect, useState} from "react";
import moment from "moment";
import LoadExamComponent from "../../Components/LoadExam/LoadExam.component";
import {BACKEND_URL} from "../../constants";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import Tabs from "../../Components/Tabs/Tabs";
import {TailSpin} from "react-loader-spinner";
import HTTPServices from "../../Utils/HTTPServices";
import LoadingPlaceHolder from "../../Components/LoadingPlaceHolder/LoadingPlaceHolder";
import DataTable from "../../Components/DataTable/DataTable.component";

const MedicinePage = () => {

    const user = JSON.parse(localStorage.getItem("user"));

    const [team, setTeam] = useState(null);
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


    useEffect(() => {
        document.title = 'SLAB - Plantel';
        let team = JSON.parse(sessionStorage.getItem('team'));
        let client = new HTTPServices();
        if (team) {
            setTeam(team);
            client.getMatches(team.id).then((response) => {
                setMatches(response.data.matches);
                setLoading(false);
            }).catch((error) => {
                console.log(error);
            });
        }

    }, []);




    return (
        <SLABTemplate title="Medicina y Rehabilitación">
            <div className="row justify-content-center">
                <span className="font-weight-bold h1">Medicina y Rehabilitación</span>
                <p className="text-center">Aquí podras cargar y visualizar el daño muscular y su recuperación. Además podras
                    revisar los avances de los jugadores en sus ejercicios de aumento de potencia muscular. </p>
                <hr/>
            </div>
            <div className="row">
                <div className="col-2">
                    <img className="w-100" src={user.company.photo} alt="team-logo"/>
                </div>
                <div className="col-10">
                    <span className="h2 color-main">{user.company.name}</span>
                </div>
            </div>
            <div className="row">
                <LoadingPlaceHolder loading={team === null ||  loading}>
                    <Tabs pages={[
                            {
                                id: 'wellness',
                                name: 'Estado de disponibilidad',
                                component: <DataTable
                                    title="Estado de disponibilidad"
                                    examType="wellness"
                                    valueLabel="wellness"
                                    players={team ? team.players : []}
                                    matches={matches}/>
                            }, {
                            id: 'subjective-load',
                            name: 'Carga Subjetiva',
                            component: <DataTable
                                title="Carga subjetiva"
                                examType="subjective_load"
                                valueLabel="load"
                                players={team ? team.players : []}
                                matches={matches}/>
                        },
                        ]}/>
                </LoadingPlaceHolder>
            </div>
        </SLABTemplate>
    )
}

export default MedicinePage;