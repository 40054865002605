import {Link, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import mixpanel from 'mixpanel-browser';
import axios from "axios";
import {BACKEND_URL} from "../../constants";
import {BallTriangle} from "react-loader-spinner";
import Swal from "sweetalert2";
import * as Sentry from "@sentry/react";
import HTTPServices from "../../Utils/HTTPServices";

const LoginPage = () => {

    let getRandomBackground = () => {
        let backgrounds = [
            "/videos/soccer-cherring.mp4",
            "/videos/soccer-warm.mp4",
        ]
        let randomIndex = Math.floor(Math.random() * backgrounds.length);
        return backgrounds[randomIndex];
    }

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [background, setBackground] = React.useState(getRandomBackground());
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();

    let handleInputChange = (e) => {
        const {name, value} = e.target;
        if (name === "email") {
            setEmail(value);
        } else if (name === "password") {
            setPassword(value);
        }
    }

    let loginRequest = async (e) => {
        setLoading(true)
        e.preventDefault();
        let client = new HTTPServices();

        try {
            if (email === "" || password === "") {
                Swal.fire("Error", "Por favor llene todos los campos", "error");
                return;
            }
            let response = await client.login(email, password);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            mixpanel.identify(response.data.user.email);
            Sentry.setUser({
                email: response.data.user.email,
                id: response.data.user.id,
                username: response.data.user.name,
            });
            client.setToken(response.data.token);

            let teamsResponse = await client.getTeams();
            let teams = teamsResponse.data.teams;
            let team = null;

            if (teams.length === 0) {
                Swal.fire("Error", "No se encontraron equipos, por favor contacte al administrador", "error");
                setLoading(false);
                return;
            }
            else if (teams.length === 1) {
                team = teams[0];
            } else {
                let selectedOption = await Swal.fire({
                    title: 'Selecciona tu equipo',
                    input: 'select',
                    inputOptions: teams.map((team) => {
                        return {[team.id]: team.name}
                    }),
                    inputPlaceholder: 'Selecciona tu equipo',
                    inputLabel: 'Equipos',
                    showCancelButton: true,
                    inputValidator: (value) => {
                        return new Promise((resolve) => {
                            if (value === '') {
                                resolve('Debes seleccionar un equipo')
                            } else {
                                resolve()
                            }
                        })
                    }
                });
                team = teams.find((team) => team.id === selectedOption.value);
            }

            sessionStorage.setItem("team", JSON.stringify(team));
            navigate("/home");
        } catch (error) {
            Swal.fire("Error", "Ha ocurrido un error con la conexión, por favor intente mas tarde", "error");
            Sentry.captureException(error);
        }
    }

        return (
            <div className="min-vh-100">
                <video className="w-100 h-100" style={{position: "absolute", objectFit: "cover"}} src={background}
                       autoPlay loop muted>
                </video>
                <div className="row min-vh-100 justify-content-center row align-items-center">
                    <div className="col-12 col-md-6 col-lg-4 pb-10">
                        <div className="card white-transparent">
                            <div className="card-body">
                                <img className="m-auto d-block" src="/images/slab-logo.svg" height="200" alt=""/>
                                <h1 className="card-title text-center slab-font">SLAB</h1>
                                {loading ?
                                    <div className="row justify-content-center">
                                        <BallTriangle
                                            height={100}
                                            width={100}
                                            radius={5}
                                            color="#011634"
                                            ariaLabel="ball-triangle-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                    </div>
                                    :
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                placeholder="ronald@dinho.com"
                                                name="email"
                                                value={email}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="password">Contraseña</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="password"
                                                placeholder="*******"
                                                name="password"
                                                value={password}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="form-check mt-2">
                                            <input type="checkbox" className="form-check-input" id="remember"/>
                                            <label className="form-check-label" htmlFor="remember">mantener sesión
                                                abierta</label>
                                        </div>
                                        <div className="row justify-content-end">
                                            <button onClick={loginRequest}
                                                    className="btn btn-primary mt-3 mr-5">Entrar
                                            </button>
                                        </div>

                                    </form>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

export default LoginPage;