import {ResponsiveBar} from "@nivo/bar";
import {useState} from "react";

const RadiometerExam = ({exam}) => {

    const [drillDown, setDrillDown] = useState(false);


    let pHResult = exam.results.find((result) => result.name === "pH");
    let pCO2Result = exam.results.find((result) => result.name === "pCO2");
    let baseResult = exam.results.find((result) => result.name === "cHCO3-(P)c");

    const drillDownHandler = () => {
        setDrillDown(!drillDown);
    }

    return drillDown ?
                <div className="row">
                    <div className="row">
                        <div className="col-xs-12 col-md-4">
                            <button className="btn btn-link" onClick={drillDownHandler}>Volver</button>
                        </div>
                    </div>
                    <table>
                        <thead>
                        <tr className="table-header-row">
                            <th className="table-header-title">Concepto</th>
                            <th className="table-header-title">Sin carga</th>
                            <th className="table-header-title">90% de carga</th>
                            <th className="table-header-title">Rangos</th>
                        </tr>
                        </thead>
                        <tbody>
                        {exam.results.map((result, index) =>
                            <tr className="table-row">
                                <td className="table-cell">{result.name} {result.unit}</td>
                                <td className="table-cell">{result.values[0].value}</td>
                                <td className="table-cell">{result.values[1].value}</td>
                                <td className="table-cell">{result.min == result.max ? "-" : `${result.min} - ${result.max}`}</td>
                            </tr>)
                        }
                        </tbody>
                    </table>
                </div> :
                <div className="row">
            <div className="col-xs-12 col-md-4" style={{height: "350px"}}>
                <ResponsiveBar
                    onClick={drillDownHandler}
                    minValue={7}
                    maxValue={8}
                    markers={[
                        {
                            axis: 'y',
                            value: pHResult.min,
                            lineStyle: {
                                stroke: 'rgba(255,0,0,0.35)',
                                strokeWidth: 5
                            },
                            // legend: 'y marker at 300',
                            // legendOrientation: 'vertical'
                        }, {
                            axis: 'y',
                            value: pHResult.max,
                            lineStyle: {
                                stroke: 'rgba(255,0,0,0.35)',
                                strokeWidth: 5
                            },
                        }
                    ]}
                    data={[{
                        "group": "Reposo",
                        "pH": pHResult.values[0].value,
                        "pHColor": "#749BBE"
                    }, {
                        "group": "90% Carga",
                        "pH": pHResult.values[1].value,
                        "pHColor": "#1F4778"
                    }]}
                    keys={["pH"]}
                    indexBy="group"
                    colors={({ id, data }) => data[`${id}Color`]}
                    margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                    padding={0.3}
                    labelTextColor={"#FFFFFF"}
                    valueScale={{ type: 'linear', clamp: true }}
                    indexScale={{ type: 'band', round: true }}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Carga',
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'pH',
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                />
            </div>
            <div className="col-xs-12 col-md-4" style={{height: "350px"}}>
                <ResponsiveBar
                    onClick={drillDownHandler}
                    markers={[{
                            axis: 'y',
                            value: pCO2Result.max,
                            lineStyle: {
                                stroke: 'rgba(255,0,0,0.35)',
                                strokeWidth: 5
                            },
                        }
                    ]}
                    data={[{
                        "group": "Reposo",
                        "pCO2": pCO2Result.values[0].value,
                        "pCO2Color": "#749BBE"
                    }, {
                        "group": "90% Carga",
                        "pCO2": pCO2Result.values[1].value,
                        "pCO2Color": "#1F4778"
                    }]}
                    keys={["pCO2"]}
                    labelTextColor={"#FFFFFF"}
                    indexBy="group"
                    colors={({ id, data }) => data[`${id}Color`]}
                    margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Carga',
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'pCO2',
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                />
            </div>
            <div className="col-xs-12 col-md-4" style={{height: "350px"}}>
                <ResponsiveBar
                    onClick={drillDownHandler}
                    markers={[
                        {
                            axis: 'y',
                            value: baseResult.min,
                            lineStyle: {
                                stroke: 'rgba(255,0,0,0.35)',
                                strokeWidth: 5
                            },
                            // legend: 'y marker at 300',
                            // legendOrientation: 'vertical'
                        }, {
                            axis: 'y',
                            value: baseResult.max,
                            lineStyle: {
                                stroke: 'rgba(255,0,0,0.35)',
                                strokeWidth: 5
                            },
                        }
                    ]}
                    data={[{
                        "group": "Reposo",
                        "HCO3": baseResult.values[0].value,
                        "HCO3Color": "#749BBE"
                    }, {
                        "group": "90% Carga",
                        "HCO3": baseResult.values[1].value,
                        "HCO3Color": "#1F4778"
                    }]}
                    keys={["HCO3"]}
                    labelTextColor={"#FFFFFF"}
                    indexBy="group"
                    colors={({ id, data }) => data[`${id}Color`]}
                    margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                    padding={0.2}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Carga',
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'HCO3',
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                />
            </div>
        </div>;

}

export default RadiometerExam;