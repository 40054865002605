import React, {useEffect, useState} from "react";
import moment from "moment";
import {median, mu, round, max, min} from "../../Utils/utils";
import Select from "react-select";

const SharpLoadTable = ({GPSdata}) => {

    const user = JSON.parse(localStorage.getItem('user'));

    let cols =
        user.company.id === "65ea346653f5498fc1401f46" ?
            [
                {name: "Distancia Total", unit: "m"},
                {name: "Distancia Relativa(m/min)", unit: "m/min"},
                {name: "Distancia Vel 18-21 km/h", unit: "m"},
                {name: "Distancia Vel 21-24 km/h", unit: "m"},
                {name: "Distancia Vel >24 km/h", unit: "m"},
                {name: "# Aceleraciones", unit: ""},
                {name: "Distancia Aceleraciones", unit: "m"},
                {name: "# Desaceleraciones", unit: ""},
                {name: "Distancia Desaceleraciones", unit: "m"},
            ] : [
                {name: "Distancia Total", unit: "m"},
                {name: "Distancia Relativa(m/min)", unit: "m/min"},
                {name: "# Sprints Alta Intensidad", unit: ""},
                {name: "# Desaceleraciones Alta Intensidad", unit: ""},
                {name: "High Metabolic Load Distance", unit: "m"},
                {name: "Distancia Alta Intensidad(>21)", unit: "m"},
                {name: "Distancia Sprint(>24)", unit: "m"},
                {name: "# Sprints", unit: ""},
                {name: "Smax", unit: "m/s"},
            ]

    let timeCol = user.company.id === "65ea346653f5498fc1401f46" ? "Tiempo final" : "T"

    const aggMethods = [
        {label: "Media", value: "mean", method: mu},
        {label: "Mediana", value: "median", method: median},
        {label: "Máximo", value: "max", method: max},
        {label: "Mínimo", value: "min", method: min},
    ]

    const [matchs, setMatchs] = useState([]);
    const [winowSize, setWindowSize] = useState(4);
    const team = JSON.parse(sessionStorage.getItem('team'));
    const [playerValues, setPlayerValues] = useState([]);
    const [aggMethod, setAggMethod] = useState(aggMethods[2]);

    useEffect(() => {
        if (GPSdata.length === 0) return;
        let matchs = GPSdata.map((match) => {
            let data = match.data.map((player) => {
                let values = cols.map((col) => {
                    return player.data[col.name]
                })
                return {name: player.name, data: values}
            })
            return {date: match.date, data: data}
        })
        setMatchs(matchs)

        let playerValues = proccessPlayerValues();

        playerValues = playerValues.sort((a, b) => {
            if (a.data[0].date === b.data[0].date)
                return a.data[0][timeCol] > b.data[0][timeCol] ? -1 : 1
            else
                return a.data[0].date > b.data[0].date ? -1 : 1
        })

        setPlayerValues(playerValues)

    }, [GPSdata, winowSize]);

    const proccessPlayerValues = () => {

        let chartData = GPSdata.flatMap((match) => {
            let group = `${moment(match.date).format("DD/MM")}`;
            let values = match.data
                .map((player) => {
                    let datum = {
                        position: player.player.position,
                        name: player.player.name,
                        values: {date: match.date,}
                    }
                    cols.map((col) => {
                        datum.values[col.name] = player.data[col.name]
                    })

                    datum.values[timeCol] = player.data[timeCol]/60;
                    return datum
                })
            return values
        })

        const arrayHashmap = chartData.reduce((obj, item) => {
            obj[item.name] ? obj[item.name].data.push(item.values) : (obj[item.name] = { ...item, data: [item.values]});
            return obj;
        }, {});

        chartData = Object.values(arrayHashmap);

        return chartData;
    }

    return (
        <div className="mt-3">
            <div className="row">
                <span className="h3">Carga aguda V/S Carga crónica</span>
            </div>
            <div className="row">
                <div className="col-4">
                    <Select
                        placeholder="Seleccione método de agregación"
                        options={aggMethods}
                        value={aggMethod}
                        onChange={(option) => setAggMethod(option)}
                    />
                </div>
                <div className="col-8">
                    <div className="form-group">
                        <label htmlFor="" className="pr-3">Números de partidos a considerar carga crónica:</label>
                        <input type="number" step="1" min={1} max={GPSdata.length - 1} value={winowSize} onChange={(e) => {
                            setWindowSize(parseInt(e.target.value))
                        }}/>
                    </div>
                </div>
            </div>
            <div className="row">
                <table className="table table-bordered table-hover">
                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Fecha</th>
                        <th>Minutos</th>
                        {cols.map((col) =>
                            <th key={col.name}>{col.name}</th>
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    {playerValues.map((player) => {
                        let [lastData, ...otherData] = player.data;
                        otherData = otherData.slice(0, winowSize);

                        let means = {}
                        cols.map((col) => {
                            let values = otherData.map((item) => item[col.name]);
                            let mean = aggMethod.method(values)
                            means[col.name] = mean;
                            return mean
                        })

                        return <tr key={player.name}>
                            <td>{player.name}</td>
                            <td>{moment(lastData.date).format("DD/MM")}</td>
                            <td>{round(lastData[timeCol], 0)}</td>
                            {cols.map((col) => {
                                let value = means[col.name] ?
                                    lastData[col.name] / means[col.name] * 100 : -1;
                                let legend =
                                    `${round(lastData[col.name], 0)} ${col.unit}/${round(means[col.name], 0)} ${col.unit}`

                                let color = "#ACF3AE";

                                if (value > 125) {
                                    color = "#fca4a4";
                                } else if (value > 50 && value < 75) {
                                    color = "#DAFFD5";
                                } else if ((value > 25 && value < 50) || (value > 105 && value < 125)) {
                                    color = "#f7fca4";
                                } else if (value < 25) {
                                    color = "#fca4a4";
                                }

                                return <td key={col.name} style={{backgroundColor: color}}>
                                    <span>{value >= 0 ? `${round(value, 0)}%` : "-"}</span>
                                    <p style={{fontSize: "12px", fontWeight: "bold"}}>{value >= 0 && legend}</p>
                                </td>
                                }
                            )}
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SharpLoadTable;