import SweatIndexCalculator from "../ExamIndexCalculators/SweatIndexCalculator";

const SweatStatusCalculator = (exam, player) => {

    let results = SweatIndexCalculator(exam);
    let status = [];

    if (results.sodium > 25) {
        status.push({
            value: 2,
            recommendation: `Se sugiere suplementación deportiva para regular sodio (Ej: sales de rehidratación oral)`,
            name: "Perdida de sodio",
            dif: `${results.sodium} mmol/L`,
            reference: "25 mmol/L",
        });
    }

    return status;
}

export default SweatStatusCalculator;
