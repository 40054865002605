import {useState} from "react";
import {ResponsiveBar} from "@nivo/bar";

const DXAExamComponent = ({exam}) => {

    const [drillDown, setDrillDown] = useState(false);


    let cols = exam.results.map((result) => result.name);
    let rows = {};
    exam.results.forEach((result) => {
        result.values.forEach((value) => {
            if (rows[value.name] === undefined) {
                rows[value.name] = [];
            }
            rows[value.name].push(value.value) ;
        })
    });

    let maxFatZone = "";
    let maxFatZonePercentage = 0;
    Object.keys(rows).map((key) => {
        if (key === "Total") return;
        if (rows[key][0] > maxFatZonePercentage) {
            maxFatZonePercentage = rows[key][0];
            maxFatZone = key;
        }
    })


    let totalLeanIndex = 3;
    let totalWeightIndex = 1;

    let totalLean = rows["Total"][totalLeanIndex];
    let totalWeight = rows["Total"][totalWeightIndex] ;


    let fatPercentage = rows["Total"][0];
    let leanPercentage = 100 * (totalLean / totalWeight);

    if (totalLean > 1000)
        leanPercentage = (100 * (totalLean/1000 / totalWeight));




    let chartData = []
    Object.keys(rows).map((key) => {

        if (key === "Total") return;
        let row = rows[key];
        chartData.push({
            "group": key,
            "Composicion": row[0],
            "ComposicionColor": "#1F4778"
        })
    })

    return drillDown ?
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col-xs-12 col-md-4">
                                <button className="btn btn-link" onClick={() => setDrillDown(!drillDown)}>Volver</button>
                            </div>
                        </div>
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th scope="col">Concepto</th>
                                {cols.map((col, index) => {
                                    return (
                                        <th key={index} scope="col">{col}</th>
                                    )
                                })}
                            </tr>
                            </thead>
                            <tbody>
                            {Object.keys(rows).map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{row}</td>
                                        {rows[row].map((value, index) => {
                                            return (
                                                <td key={index}>{value}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
        <div className="row">
            <div className="col-md-4 col-xs-12">
                <div className="card mt-3">
                    <div className="card-header">
                        <h5 className="card-title">% Grasa</h5>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">{fatPercentage.toFixed(2)}%</h5>
                    </div>
                </div>
                <div className="card mt-3">
                    <div className="card-header">
                        <h5 className="card-title">% Magro</h5>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">{leanPercentage.toFixed(2)}%</h5>
                    </div>
                </div>
                <div className="card mt-3">
                    <div className="card-header">
                        <h5 className="card-title">Zona con mayor % grasa</h5>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">{maxFatZone} ({maxFatZonePercentage.toFixed(2)}%)</h5>
                    </div>
                </div>
            </div>
            <div className="col-md-8 col-xs-12">
                <ResponsiveBar
                    onClick={() => setDrillDown(!drillDown)}
                    data={chartData}
                    keys={["Composicion"]}
                    indexBy="group"
                    margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    label={d => `${d.value} %`}
                    labelTextColor={"#FFFFFF"}
                    colors={({ id, data }) => data[`${id}Color`]}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Porcentaje Grasa (%)',
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                />
            </div>
        </div>
}

export default DXAExamComponent;