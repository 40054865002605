import SLABTemplate from "../../Template/SLABTemplate.component";
import React, {useEffect, useState} from "react";
import LoadExamComponent from "../../Components/LoadExam/LoadExam.component";
import LoadGPSDataComponent from "../../Components/LoadGPSData/LoadGPSData.component";
import HTTPServices from "../../Utils/HTTPServices";
import Tabs from "../../Components/Tabs/Tabs";
import GPSChartComponent from "../../Components/GPS_Charts/GPSChart.component";

const PerformancePage = () => {
    const [tab, setTab] = useState("performance");
    const user = JSON.parse(localStorage.getItem("user"));

    const [GPSData, setGPSData] = useState([]);

    useEffect(() => {
        let client = new HTTPServices();
        client.getGPS()
            .then((response) => {
                setGPSData(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }, []);

    return (
        <SLABTemplate title="Data y Rendimiento">
            <div className="row justify-content-center">
                <span className="font-weight-bold h1">Data y Rendimiento</span>
                <p className="text-center">Aquí podras cargar y visualizar los datos relacionados a GPS para conocer el rendimiento, carga y
                    caracteristicas de los jugadores tanto en la cancha como en entrenamientos. </p>
                <hr/>
            </div>
            <div className="row">
                <div className="col-2">
                    <img className="w-100" src={user.company.photo} alt="team-logo"/>
                </div>
                <div className="col-10">
                    <span className="h2 color-main">{user.company.name}</span>
                </div>
            </div>
            <div className="row mt-5">
                <Tabs
                    pages={[
                        {
                            id: "performance",
                            name: "Carga de datos GPS",
                            component: <div className="row mt-5">
                                <LoadGPSDataComponent
                                    title="Rendimiento"
                                    description="Carga los resultados de los GPS, con está información se podran planificar las cargas previo y post partidos para obtener una optima recuperación, asi como entender la condición de cadad jugador en base a sus limitaciones"
                                    templateURL="/check/gps/template/"
                                    uploadURL="/check/gps/"
                                />
                            </div>
                        }
                    ]}
                />
            </div>

        </SLABTemplate>
    )
}

export default PerformancePage;