import moment from "moment/moment";

export const calculateSharpLoad = (match, method, col) => {
    let values = [];
    match.data.map((playerGPSData) => {
        let value = playerGPSData.data[col];
        values.push(value);
    });
    // Clean nan values and return the method
    values = values.filter((value) => !isNaN(value));
    return method(values);

}

export const calculateChronicLoad = (data, method, col) => {
    let values =  data.map((match) => {
        return calculateSharpLoad(match, method, col)
    })
    values = values.filter((value) => !isNaN(value));
    return method(values);
}

export const teamSharpLoad = (data, method, col) => {


}

export const teamChronicLoad = (data, method, col) => {

}


export const playerSharpLoad = (player, match, method, col) => {
    let values = [];
    match.data
        .filter((playerGPSData) => playerGPSData.player.id === player.id)
        .map((playerGPSData) => {
        let value = playerGPSData.data[col];
        values.push(value);
    });
    values = values.filter((value) => !isNaN(value));
    return method(values);
}

export const playerChronicLoad = (player, data, method, col) => {
    let values =  data.map((match) => {
        return playerSharpLoad(player, match, method, col)
    })
    values = values.filter((value) => !isNaN(value));
    return method(values);
}

